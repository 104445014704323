/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TExamQuestionType } from '../../models/t-exam-question-type';
import { TraditionalExamAnswer } from '../../models/traditional-exam-answer';
import { UpdateTExamQuestionCommandResult } from '../../models/update-t-exam-question-command-result';

export interface ApiQyasExamUpdateTExamQuestionPatch$Plain$Params {
      body?: {
'Id'?: number;
'AudioFile'?: Blob;
'AudioPlayTimes'?: number;
'Title'?: string;
'Image'?: Blob;
'TExamId'?: number;
'Sort'?: number;
'Degree'?: number;
'QuestionType'?: TExamQuestionType;
'CorrectTextAnswer'?: string;
'Answers'?: Array<TraditionalExamAnswer>;
}
}

export function apiQyasExamUpdateTExamQuestionPatch$Plain(http: HttpClient, rootUrl: string, params?: ApiQyasExamUpdateTExamQuestionPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamQuestionCommandResult>> {
  const rb = new RequestBuilder(rootUrl, apiQyasExamUpdateTExamQuestionPatch$Plain.PATH, 'patch');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UpdateTExamQuestionCommandResult>;
    })
  );
}

apiQyasExamUpdateTExamQuestionPatch$Plain.PATH = '/api/QyasExam/UpdateTExamQuestion';
