/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiQyasExamCreateExamPost } from '../fn/qyas-exam/api-qyas-exam-create-exam-post';
import { ApiQyasExamCreateExamPost$Params } from '../fn/qyas-exam/api-qyas-exam-create-exam-post';
import { apiQyasExamCreateExamPost$Plain } from '../fn/qyas-exam/api-qyas-exam-create-exam-post-plain';
import { ApiQyasExamCreateExamPost$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-create-exam-post-plain';
import { apiQyasExamCreateTExamQuestionPost } from '../fn/qyas-exam/api-qyas-exam-create-t-exam-question-post';
import { ApiQyasExamCreateTExamQuestionPost$Params } from '../fn/qyas-exam/api-qyas-exam-create-t-exam-question-post';
import { apiQyasExamCreateTExamQuestionPost$Plain } from '../fn/qyas-exam/api-qyas-exam-create-t-exam-question-post-plain';
import { ApiQyasExamCreateTExamQuestionPost$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-create-t-exam-question-post-plain';
import { apiQyasExamDeleteExamDelete } from '../fn/qyas-exam/api-qyas-exam-delete-exam-delete';
import { ApiQyasExamDeleteExamDelete$Params } from '../fn/qyas-exam/api-qyas-exam-delete-exam-delete';
import { apiQyasExamDeleteExamDelete$Plain } from '../fn/qyas-exam/api-qyas-exam-delete-exam-delete-plain';
import { ApiQyasExamDeleteExamDelete$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-delete-exam-delete-plain';
import { apiQyasExamDeleteTExamQuestionDelete } from '../fn/qyas-exam/api-qyas-exam-delete-t-exam-question-delete';
import { ApiQyasExamDeleteTExamQuestionDelete$Params } from '../fn/qyas-exam/api-qyas-exam-delete-t-exam-question-delete';
import { apiQyasExamDeleteTExamQuestionDelete$Plain } from '../fn/qyas-exam/api-qyas-exam-delete-t-exam-question-delete-plain';
import { ApiQyasExamDeleteTExamQuestionDelete$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-delete-t-exam-question-delete-plain';
import { apiQyasExamGetExamPost } from '../fn/qyas-exam/api-qyas-exam-get-exam-post';
import { ApiQyasExamGetExamPost$Params } from '../fn/qyas-exam/api-qyas-exam-get-exam-post';
import { apiQyasExamGetExamPost$Plain } from '../fn/qyas-exam/api-qyas-exam-get-exam-post-plain';
import { ApiQyasExamGetExamPost$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-get-exam-post-plain';
import { apiQyasExamGetExamsPost } from '../fn/qyas-exam/api-qyas-exam-get-exams-post';
import { ApiQyasExamGetExamsPost$Params } from '../fn/qyas-exam/api-qyas-exam-get-exams-post';
import { apiQyasExamGetExamsPost$Plain } from '../fn/qyas-exam/api-qyas-exam-get-exams-post-plain';
import { ApiQyasExamGetExamsPost$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-get-exams-post-plain';
import { apiQyasExamGetStudentAnsweredExamPost } from '../fn/qyas-exam/api-qyas-exam-get-student-answered-exam-post';
import { ApiQyasExamGetStudentAnsweredExamPost$Params } from '../fn/qyas-exam/api-qyas-exam-get-student-answered-exam-post';
import { apiQyasExamGetStudentAnsweredExamPost$Plain } from '../fn/qyas-exam/api-qyas-exam-get-student-answered-exam-post-plain';
import { ApiQyasExamGetStudentAnsweredExamPost$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-get-student-answered-exam-post-plain';
import { apiQyasExamGetStudentAnsweredExamsPost } from '../fn/qyas-exam/api-qyas-exam-get-student-answered-exams-post';
import { ApiQyasExamGetStudentAnsweredExamsPost$Params } from '../fn/qyas-exam/api-qyas-exam-get-student-answered-exams-post';
import { apiQyasExamGetStudentAnsweredExamsPost$Plain } from '../fn/qyas-exam/api-qyas-exam-get-student-answered-exams-post-plain';
import { ApiQyasExamGetStudentAnsweredExamsPost$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-get-student-answered-exams-post-plain';
import { apiQyasExamRepeatExamTimeInsurePatch } from '../fn/qyas-exam/api-qyas-exam-repeat-exam-time-insure-patch';
import { ApiQyasExamRepeatExamTimeInsurePatch$Params } from '../fn/qyas-exam/api-qyas-exam-repeat-exam-time-insure-patch';
import { apiQyasExamRepeatExamTimeInsurePatch$Plain } from '../fn/qyas-exam/api-qyas-exam-repeat-exam-time-insure-patch-plain';
import { ApiQyasExamRepeatExamTimeInsurePatch$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-repeat-exam-time-insure-patch-plain';
import { apiQyasExamStartSolvingPatch } from '../fn/qyas-exam/api-qyas-exam-start-solving-patch';
import { ApiQyasExamStartSolvingPatch$Params } from '../fn/qyas-exam/api-qyas-exam-start-solving-patch';
import { apiQyasExamStartSolvingPatch$Plain } from '../fn/qyas-exam/api-qyas-exam-start-solving-patch-plain';
import { ApiQyasExamStartSolvingPatch$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-start-solving-patch-plain';
import { apiQyasExamStudentSubmitExamPatch } from '../fn/qyas-exam/api-qyas-exam-student-submit-exam-patch';
import { ApiQyasExamStudentSubmitExamPatch$Params } from '../fn/qyas-exam/api-qyas-exam-student-submit-exam-patch';
import { apiQyasExamStudentSubmitExamPatch$Plain } from '../fn/qyas-exam/api-qyas-exam-student-submit-exam-patch-plain';
import { ApiQyasExamStudentSubmitExamPatch$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-student-submit-exam-patch-plain';
import { apiQyasExamUpdateExamPatch } from '../fn/qyas-exam/api-qyas-exam-update-exam-patch';
import { ApiQyasExamUpdateExamPatch$Params } from '../fn/qyas-exam/api-qyas-exam-update-exam-patch';
import { apiQyasExamUpdateExamPatch$Plain } from '../fn/qyas-exam/api-qyas-exam-update-exam-patch-plain';
import { ApiQyasExamUpdateExamPatch$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-update-exam-patch-plain';
import { apiQyasExamUpdateTExamQuestionPatch } from '../fn/qyas-exam/api-qyas-exam-update-t-exam-question-patch';
import { ApiQyasExamUpdateTExamQuestionPatch$Params } from '../fn/qyas-exam/api-qyas-exam-update-t-exam-question-patch';
import { apiQyasExamUpdateTExamQuestionPatch$Plain } from '../fn/qyas-exam/api-qyas-exam-update-t-exam-question-patch-plain';
import { ApiQyasExamUpdateTExamQuestionPatch$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-update-t-exam-question-patch-plain';
import { CreateTExamCommandResult } from '../models/create-t-exam-command-result';
import { CreateTExamQuestionCommandResult } from '../models/create-t-exam-question-command-result';
import { DeleteTExamCommandResult } from '../models/delete-t-exam-command-result';
import { DeleteTExamQuestionCommandResult } from '../models/delete-t-exam-question-command-result';
import { GetExamQueryResult } from '../models/get-exam-query-result';
import { GetExamsQueryResult } from '../models/get-exams-query-result';
import { GetStudentAnsweredExamQueryResult } from '../models/get-student-answered-exam-query-result';
import { GetStudentAnsweredExamsQueryResult } from '../models/get-student-answered-exams-query-result';
import { RepeatExamTimeInsureCommandResult } from '../models/repeat-exam-time-insure-command-result';
import { StartSolvingCommandResult } from '../models/start-solving-command-result';
import { SubmitExamCommandResult } from '../models/submit-exam-command-result';
import { UpdateTExamCommandResult } from '../models/update-t-exam-command-result';
import { UpdateTExamQuestionCommandResult } from '../models/update-t-exam-question-command-result';

@Injectable({ providedIn: 'root' })
export class QyasExamService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiQyasExamGetExamPost()` */
  static readonly ApiQyasExamGetExamPostPath = '/api/QyasExam/GetExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetExamPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamPost$Plain$Response(params?: ApiQyasExamGetExamPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamQueryResult>> {
    return apiQyasExamGetExamPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetExamPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamPost$Plain(params?: ApiQyasExamGetExamPost$Plain$Params, context?: HttpContext): Observable<GetExamQueryResult> {
    return this.apiQyasExamGetExamPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamQueryResult>): GetExamQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetExamPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamPost$Response(params?: ApiQyasExamGetExamPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamQueryResult>> {
    return apiQyasExamGetExamPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetExamPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamPost(params?: ApiQyasExamGetExamPost$Params, context?: HttpContext): Observable<GetExamQueryResult> {
    return this.apiQyasExamGetExamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamQueryResult>): GetExamQueryResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamGetExamsPost()` */
  static readonly ApiQyasExamGetExamsPostPath = '/api/QyasExam/GetExams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetExamsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamsPost$Plain$Response(params?: ApiQyasExamGetExamsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamsQueryResult>> {
    return apiQyasExamGetExamsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetExamsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamsPost$Plain(params?: ApiQyasExamGetExamsPost$Plain$Params, context?: HttpContext): Observable<GetExamsQueryResult> {
    return this.apiQyasExamGetExamsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamsQueryResult>): GetExamsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetExamsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamsPost$Response(params?: ApiQyasExamGetExamsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamsQueryResult>> {
    return apiQyasExamGetExamsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetExamsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamsPost(params?: ApiQyasExamGetExamsPost$Params, context?: HttpContext): Observable<GetExamsQueryResult> {
    return this.apiQyasExamGetExamsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamsQueryResult>): GetExamsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamCreateExamPost()` */
  static readonly ApiQyasExamCreateExamPostPath = '/api/QyasExam/CreateExam';

  /**
   * Title:اختبار تيست
   * Description:وصف الامتحان الخطير
   * DurationInMinutes:1
   * StartDate:2024-09-26T00:00:00Z
   * EndDate:2024-09-26T11:59:00Z
   * TeacherId:46F84B1A-24D9-4BEE-93DD-531D06C6EBB5
   * CourseTeacherIDs:7D29C67B-8064-4BDE-7A2E-08DC4CB12337
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamCreateExamPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateExamPost$Plain$Response(params?: ApiQyasExamCreateExamPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTExamCommandResult>> {
    return apiQyasExamCreateExamPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Title:اختبار تيست
   * Description:وصف الامتحان الخطير
   * DurationInMinutes:1
   * StartDate:2024-09-26T00:00:00Z
   * EndDate:2024-09-26T11:59:00Z
   * TeacherId:46F84B1A-24D9-4BEE-93DD-531D06C6EBB5
   * CourseTeacherIDs:7D29C67B-8064-4BDE-7A2E-08DC4CB12337
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamCreateExamPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateExamPost$Plain(params?: ApiQyasExamCreateExamPost$Plain$Params, context?: HttpContext): Observable<CreateTExamCommandResult> {
    return this.apiQyasExamCreateExamPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTExamCommandResult>): CreateTExamCommandResult => r.body)
    );
  }

  /**
   * Title:اختبار تيست
   * Description:وصف الامتحان الخطير
   * DurationInMinutes:1
   * StartDate:2024-09-26T00:00:00Z
   * EndDate:2024-09-26T11:59:00Z
   * TeacherId:46F84B1A-24D9-4BEE-93DD-531D06C6EBB5
   * CourseTeacherIDs:7D29C67B-8064-4BDE-7A2E-08DC4CB12337
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamCreateExamPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateExamPost$Response(params?: ApiQyasExamCreateExamPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTExamCommandResult>> {
    return apiQyasExamCreateExamPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Title:اختبار تيست
   * Description:وصف الامتحان الخطير
   * DurationInMinutes:1
   * StartDate:2024-09-26T00:00:00Z
   * EndDate:2024-09-26T11:59:00Z
   * TeacherId:46F84B1A-24D9-4BEE-93DD-531D06C6EBB5
   * CourseTeacherIDs:7D29C67B-8064-4BDE-7A2E-08DC4CB12337
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamCreateExamPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateExamPost(params?: ApiQyasExamCreateExamPost$Params, context?: HttpContext): Observable<CreateTExamCommandResult> {
    return this.apiQyasExamCreateExamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTExamCommandResult>): CreateTExamCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamUpdateExamPatch()` */
  static readonly ApiQyasExamUpdateExamPatchPath = '/api/QyasExam/UpdateExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamUpdateExamPatch$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateExamPatch$Plain$Response(params?: ApiQyasExamUpdateExamPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamCommandResult>> {
    return apiQyasExamUpdateExamPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamUpdateExamPatch$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateExamPatch$Plain(params?: ApiQyasExamUpdateExamPatch$Plain$Params, context?: HttpContext): Observable<UpdateTExamCommandResult> {
    return this.apiQyasExamUpdateExamPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTExamCommandResult>): UpdateTExamCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamUpdateExamPatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateExamPatch$Response(params?: ApiQyasExamUpdateExamPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamCommandResult>> {
    return apiQyasExamUpdateExamPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamUpdateExamPatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateExamPatch(params?: ApiQyasExamUpdateExamPatch$Params, context?: HttpContext): Observable<UpdateTExamCommandResult> {
    return this.apiQyasExamUpdateExamPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTExamCommandResult>): UpdateTExamCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamDeleteExamDelete()` */
  static readonly ApiQyasExamDeleteExamDeletePath = '/api/QyasExam/DeleteExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamDeleteExamDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamDeleteExamDelete$Plain$Response(params?: ApiQyasExamDeleteExamDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTExamCommandResult>> {
    return apiQyasExamDeleteExamDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamDeleteExamDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamDeleteExamDelete$Plain(params?: ApiQyasExamDeleteExamDelete$Plain$Params, context?: HttpContext): Observable<DeleteTExamCommandResult> {
    return this.apiQyasExamDeleteExamDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTExamCommandResult>): DeleteTExamCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamDeleteExamDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamDeleteExamDelete$Response(params?: ApiQyasExamDeleteExamDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTExamCommandResult>> {
    return apiQyasExamDeleteExamDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamDeleteExamDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamDeleteExamDelete(params?: ApiQyasExamDeleteExamDelete$Params, context?: HttpContext): Observable<DeleteTExamCommandResult> {
    return this.apiQyasExamDeleteExamDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTExamCommandResult>): DeleteTExamCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamCreateTExamQuestionPost()` */
  static readonly ApiQyasExamCreateTExamQuestionPostPath = '/api/QyasExam/CreateTExamQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamCreateTExamQuestionPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateTExamQuestionPost$Plain$Response(params?: ApiQyasExamCreateTExamQuestionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTExamQuestionCommandResult>> {
    return apiQyasExamCreateTExamQuestionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamCreateTExamQuestionPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateTExamQuestionPost$Plain(params?: ApiQyasExamCreateTExamQuestionPost$Plain$Params, context?: HttpContext): Observable<CreateTExamQuestionCommandResult> {
    return this.apiQyasExamCreateTExamQuestionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTExamQuestionCommandResult>): CreateTExamQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamCreateTExamQuestionPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateTExamQuestionPost$Response(params?: ApiQyasExamCreateTExamQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTExamQuestionCommandResult>> {
    return apiQyasExamCreateTExamQuestionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamCreateTExamQuestionPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateTExamQuestionPost(params?: ApiQyasExamCreateTExamQuestionPost$Params, context?: HttpContext): Observable<CreateTExamQuestionCommandResult> {
    return this.apiQyasExamCreateTExamQuestionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTExamQuestionCommandResult>): CreateTExamQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamUpdateTExamQuestionPatch()` */
  static readonly ApiQyasExamUpdateTExamQuestionPatchPath = '/api/QyasExam/UpdateTExamQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamUpdateTExamQuestionPatch$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateTExamQuestionPatch$Plain$Response(params?: ApiQyasExamUpdateTExamQuestionPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamQuestionCommandResult>> {
    return apiQyasExamUpdateTExamQuestionPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamUpdateTExamQuestionPatch$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateTExamQuestionPatch$Plain(params?: ApiQyasExamUpdateTExamQuestionPatch$Plain$Params, context?: HttpContext): Observable<UpdateTExamQuestionCommandResult> {
    return this.apiQyasExamUpdateTExamQuestionPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTExamQuestionCommandResult>): UpdateTExamQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamUpdateTExamQuestionPatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateTExamQuestionPatch$Response(params?: ApiQyasExamUpdateTExamQuestionPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamQuestionCommandResult>> {
    return apiQyasExamUpdateTExamQuestionPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamUpdateTExamQuestionPatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateTExamQuestionPatch(params?: ApiQyasExamUpdateTExamQuestionPatch$Params, context?: HttpContext): Observable<UpdateTExamQuestionCommandResult> {
    return this.apiQyasExamUpdateTExamQuestionPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTExamQuestionCommandResult>): UpdateTExamQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamDeleteTExamQuestionDelete()` */
  static readonly ApiQyasExamDeleteTExamQuestionDeletePath = '/api/QyasExam/DeleteTExamQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamDeleteTExamQuestionDelete$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamDeleteTExamQuestionDelete$Plain$Response(params?: ApiQyasExamDeleteTExamQuestionDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTExamQuestionCommandResult>> {
    return apiQyasExamDeleteTExamQuestionDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamDeleteTExamQuestionDelete$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamDeleteTExamQuestionDelete$Plain(params?: ApiQyasExamDeleteTExamQuestionDelete$Plain$Params, context?: HttpContext): Observable<DeleteTExamQuestionCommandResult> {
    return this.apiQyasExamDeleteTExamQuestionDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTExamQuestionCommandResult>): DeleteTExamQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamDeleteTExamQuestionDelete()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamDeleteTExamQuestionDelete$Response(params?: ApiQyasExamDeleteTExamQuestionDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTExamQuestionCommandResult>> {
    return apiQyasExamDeleteTExamQuestionDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamDeleteTExamQuestionDelete$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamDeleteTExamQuestionDelete(params?: ApiQyasExamDeleteTExamQuestionDelete$Params, context?: HttpContext): Observable<DeleteTExamQuestionCommandResult> {
    return this.apiQyasExamDeleteTExamQuestionDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTExamQuestionCommandResult>): DeleteTExamQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamStudentSubmitExamPatch()` */
  static readonly ApiQyasExamStudentSubmitExamPatchPath = '/api/QyasExam/StudentSubmitExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamStudentSubmitExamPatch$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamStudentSubmitExamPatch$Plain$Response(params?: ApiQyasExamStudentSubmitExamPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SubmitExamCommandResult>> {
    return apiQyasExamStudentSubmitExamPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamStudentSubmitExamPatch$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamStudentSubmitExamPatch$Plain(params?: ApiQyasExamStudentSubmitExamPatch$Plain$Params, context?: HttpContext): Observable<SubmitExamCommandResult> {
    return this.apiQyasExamStudentSubmitExamPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubmitExamCommandResult>): SubmitExamCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamStudentSubmitExamPatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamStudentSubmitExamPatch$Response(params?: ApiQyasExamStudentSubmitExamPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<SubmitExamCommandResult>> {
    return apiQyasExamStudentSubmitExamPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamStudentSubmitExamPatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamStudentSubmitExamPatch(params?: ApiQyasExamStudentSubmitExamPatch$Params, context?: HttpContext): Observable<SubmitExamCommandResult> {
    return this.apiQyasExamStudentSubmitExamPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubmitExamCommandResult>): SubmitExamCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamStartSolvingPatch()` */
  static readonly ApiQyasExamStartSolvingPatchPath = '/api/QyasExam/StartSolving';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamStartSolvingPatch$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamStartSolvingPatch$Plain$Response(params?: ApiQyasExamStartSolvingPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StartSolvingCommandResult>> {
    return apiQyasExamStartSolvingPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamStartSolvingPatch$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamStartSolvingPatch$Plain(params?: ApiQyasExamStartSolvingPatch$Plain$Params, context?: HttpContext): Observable<StartSolvingCommandResult> {
    return this.apiQyasExamStartSolvingPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StartSolvingCommandResult>): StartSolvingCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamStartSolvingPatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamStartSolvingPatch$Response(params?: ApiQyasExamStartSolvingPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<StartSolvingCommandResult>> {
    return apiQyasExamStartSolvingPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamStartSolvingPatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamStartSolvingPatch(params?: ApiQyasExamStartSolvingPatch$Params, context?: HttpContext): Observable<StartSolvingCommandResult> {
    return this.apiQyasExamStartSolvingPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<StartSolvingCommandResult>): StartSolvingCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamRepeatExamTimeInsurePatch()` */
  static readonly ApiQyasExamRepeatExamTimeInsurePatchPath = '/api/QyasExam/RepeatExamTimeInsure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamRepeatExamTimeInsurePatch$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamRepeatExamTimeInsurePatch$Plain$Response(params?: ApiQyasExamRepeatExamTimeInsurePatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RepeatExamTimeInsureCommandResult>> {
    return apiQyasExamRepeatExamTimeInsurePatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamRepeatExamTimeInsurePatch$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamRepeatExamTimeInsurePatch$Plain(params?: ApiQyasExamRepeatExamTimeInsurePatch$Plain$Params, context?: HttpContext): Observable<RepeatExamTimeInsureCommandResult> {
    return this.apiQyasExamRepeatExamTimeInsurePatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RepeatExamTimeInsureCommandResult>): RepeatExamTimeInsureCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamRepeatExamTimeInsurePatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamRepeatExamTimeInsurePatch$Response(params?: ApiQyasExamRepeatExamTimeInsurePatch$Params, context?: HttpContext): Observable<StrictHttpResponse<RepeatExamTimeInsureCommandResult>> {
    return apiQyasExamRepeatExamTimeInsurePatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamRepeatExamTimeInsurePatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamRepeatExamTimeInsurePatch(params?: ApiQyasExamRepeatExamTimeInsurePatch$Params, context?: HttpContext): Observable<RepeatExamTimeInsureCommandResult> {
    return this.apiQyasExamRepeatExamTimeInsurePatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<RepeatExamTimeInsureCommandResult>): RepeatExamTimeInsureCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamGetStudentAnsweredExamsPost()` */
  static readonly ApiQyasExamGetStudentAnsweredExamsPostPath = '/api/QyasExam/GetStudentAnsweredExams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetStudentAnsweredExamsPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamGetStudentAnsweredExamsPost$Plain$Response(params?: ApiQyasExamGetStudentAnsweredExamsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentAnsweredExamsQueryResult>> {
    return apiQyasExamGetStudentAnsweredExamsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetStudentAnsweredExamsPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamGetStudentAnsweredExamsPost$Plain(params?: ApiQyasExamGetStudentAnsweredExamsPost$Plain$Params, context?: HttpContext): Observable<GetStudentAnsweredExamsQueryResult> {
    return this.apiQyasExamGetStudentAnsweredExamsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentAnsweredExamsQueryResult>): GetStudentAnsweredExamsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetStudentAnsweredExamsPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamGetStudentAnsweredExamsPost$Response(params?: ApiQyasExamGetStudentAnsweredExamsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentAnsweredExamsQueryResult>> {
    return apiQyasExamGetStudentAnsweredExamsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetStudentAnsweredExamsPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamGetStudentAnsweredExamsPost(params?: ApiQyasExamGetStudentAnsweredExamsPost$Params, context?: HttpContext): Observable<GetStudentAnsweredExamsQueryResult> {
    return this.apiQyasExamGetStudentAnsweredExamsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentAnsweredExamsQueryResult>): GetStudentAnsweredExamsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamGetStudentAnsweredExamPost()` */
  static readonly ApiQyasExamGetStudentAnsweredExamPostPath = '/api/QyasExam/GetStudentAnsweredExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetStudentAnsweredExamPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamGetStudentAnsweredExamPost$Plain$Response(params?: ApiQyasExamGetStudentAnsweredExamPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentAnsweredExamQueryResult>> {
    return apiQyasExamGetStudentAnsweredExamPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetStudentAnsweredExamPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamGetStudentAnsweredExamPost$Plain(params?: ApiQyasExamGetStudentAnsweredExamPost$Plain$Params, context?: HttpContext): Observable<GetStudentAnsweredExamQueryResult> {
    return this.apiQyasExamGetStudentAnsweredExamPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentAnsweredExamQueryResult>): GetStudentAnsweredExamQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetStudentAnsweredExamPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamGetStudentAnsweredExamPost$Response(params?: ApiQyasExamGetStudentAnsweredExamPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentAnsweredExamQueryResult>> {
    return apiQyasExamGetStudentAnsweredExamPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetStudentAnsweredExamPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamGetStudentAnsweredExamPost(params?: ApiQyasExamGetStudentAnsweredExamPost$Params, context?: HttpContext): Observable<GetStudentAnsweredExamQueryResult> {
    return this.apiQyasExamGetStudentAnsweredExamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentAnsweredExamQueryResult>): GetStudentAnsweredExamQueryResult => r.body)
    );
  }

}
