<button type="button" [ngClass]="{'text-white': light}" class="btn border-0 shadow-none noti-icon py-0" routerLink="/account/choose">
  <i class="bx bx-customize text-reset"></i>
  الأنظمة
</button>

  <!-- <div class="dropdown-menu dropdown-menu-xl dropdown-menu-end" ngbDropdownMenu>
    <div class="px-lg-2">
      <div class="row no-gutters">
        <div class="col">
          <a class="dropdown-icon-item  p-2" routerLink="/">
            <span >
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path d="M28.8647 0.759033V5.61701H33.7743L28.8647 0.759033Z" fill="#1a5232"/>
                <path d="M17.6912 12.0944C16.5135 12.0871 16.4179 13.9861 17.6912 14.0082C18.9352 14.0303 18.891 12.0723 17.6912 12.0944Z" fill="#1a5232"/>
                <path d="M27.0024 12.0943C25.8321 12.0796 25.6922 13.9713 27.0024 14.0081C28.239 14.0375 28.239 12.0796 27.0024 12.0943Z" fill="#1a5232"/>
                <path d="M27.8934 6.13242V0.170354C-3.551 0.288124 7.48251 -5.12926 5.55404 36.826C5.55404 38.4454 6.89366 39.7703 8.53507 39.7703C44.4768 39.5274 32.3981 45.2244 34.4443 6.64766C34.0321 6.44893 27.702 7.15554 27.8934 6.13242ZM9.80109 3.77704C10.1029 3.96105 17.0145 3.30596 16.7274 4.21867C17.0513 5.12402 9.99982 4.47629 9.72748 4.66031C9.13864 4.6235 9.146 3.76968 9.80109 3.77704ZM9.80109 6.57406C10.1029 6.75807 17.0145 6.10298 16.7274 7.01569C17.0513 7.92104 9.99982 7.27331 9.72748 7.45732C9.13864 7.42052 9.146 6.5667 9.80109 6.57406ZM31.6178 34.6179C31.5737 35.744 9.36682 34.7283 8.82949 35.0595C8.26273 35.0448 8.25537 34.1909 8.82949 34.1762H11.1407C11.3026 33.7714 10.7138 30.415 11.5897 30.4959C12.4656 30.4076 11.8768 33.7861 12.0387 34.1762H13.9009C14.1144 33.8818 13.3857 24.6516 14.3499 24.9755C15.3068 24.6148 14.5855 33.926 14.7989 34.1762H16.6611C16.8452 33.845 16.1901 27.5223 17.1101 27.7725C18.0229 27.5002 17.3751 33.8744 17.5591 34.1762H19.4214C19.6127 33.926 18.9429 25.5275 19.8704 25.9324C20.7904 25.4981 20.128 33.9554 20.3194 34.1762H22.1816C22.3582 33.7788 21.7326 29.4949 22.6306 29.6127C23.5286 29.4802 22.9029 33.8008 23.0796 34.1762H24.9418C25.1258 33.845 24.4707 27.5223 25.3908 27.7725C26.3035 27.5002 25.6558 33.8744 25.8398 34.1762H27.6726C27.886 33.9554 27.1573 21.7736 28.1216 22.2521C29.0784 21.7295 28.3571 34.0069 28.5706 34.1762C28.9754 34.3234 31.662 33.7861 31.6178 34.6179ZM28.2688 14.3027C27.6873 14.9062 26.6715 14.9578 25.9576 14.5971L23.8745 16.658C25.457 20.2647 18.9871 20.2279 20.5991 16.658L18.516 14.5971C17.9934 14.8694 17.25 14.9136 16.7274 14.5971L14.6443 16.658C15.7779 18.5276 13.1281 20.2868 11.6633 18.8662C10.0587 17.2984 12.1859 14.9872 13.9745 15.9956L16.0576 13.9346C14.3867 10.431 20.9597 10.4604 19.333 13.9346L21.4161 15.9956C21.9755 15.7012 22.7925 15.7012 23.3519 15.9956L25.435 13.9346C23.8451 9.71702 31.213 10.8726 28.2688 14.3027Z" fill="#1a5232"/>
                <path d="M22.307 16.6578C21.1366 16.6431 20.9968 18.5347 22.307 18.5716C23.5215 18.5936 23.5803 16.6652 22.307 16.6578Z" fill="#1a5232"/>
                <path d="M13.003 16.6577C11.8253 16.6504 11.7296 18.5494 13.003 18.5715C14.2028 18.5862 14.2617 16.6725 13.003 16.6577Z" fill="#1a5232"/>
                <path d="M39.067 16.6577C37.8157 16.6577 37.8157 18.5788 39.067 18.5715C40.311 18.5715 40.311 16.6577 39.067 16.6577Z" fill="#1a5232"/>
                <path d="M0.938474 29.5391C-0.312824 29.5391 -0.312824 31.4528 0.938474 31.4528C2.20449 31.4602 2.13825 29.5464 0.938474 29.5391Z" fill="#1a5232"/>
              </svg>
            </span>
            <span class="mt-2">
              نظام إدارة المشاريع
            </span>
          </a>
        </div>
        <div class="col">
          <a class="dropdown-icon-item  p-2" routerLink="/task-manager">
            <span >
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="42" viewBox="0 0 40 42" fill="none">
                <path d="M26.356 36.6165H13.6444V41.4729H26.356V36.6165Z" fill="#1a5232"/>
                <path d="M12.7116 36.6165H0V41.4729H12.7116V36.6165Z" fill="#1a5232"/>
                <path d="M26.356 25.0532H13.6444V29.9097H26.356V25.0532Z" fill="#1a5232"/>
                <path d="M29.239 2.92896H24.3286V19.3484H26.2789C26.2712 19.1326 26.2866 13.3665 26.2789 13.0273C26.2326 12.1717 28.7456 12.7036 29.1619 12.5648V2.92896H29.239Z" fill="#1a5232"/>
                <path d="M26.356 30.8345H13.6444V35.6909H26.356V30.8345Z" fill="#1a5232"/>
                <path d="M4.36312 4.85624C6.24403 4.9102 6.32883 1.85757 4.36312 1.92695C2.3974 1.85757 2.4822 4.9102 4.36312 4.85624Z" fill="#1a5232"/>
                <path d="M1.47977 24.1277C3.36069 24.1817 3.44548 21.1291 1.47977 21.1984C-0.455109 21.1984 -0.455109 24.1354 1.47977 24.1277Z" fill="#1a5232"/>
                <path d="M11.771 9.6355H6.86058V19.2713H11.771V9.6355Z" fill="#1a5232"/>
                <path d="M23.3953 2.46653V-0.000244141H18.562C18.562 4.38599 18.562 14.785 18.562 19.2715H23.3953V2.46653Z" fill="#1a5232"/>
                <path d="M17.545 7.7085H12.7117C12.7117 8.22498 12.7117 18.4004 12.7117 19.2715H17.545V7.7085Z" fill="#1a5232"/>
                <path d="M12.7116 30.8345H0V35.6909H12.7116V30.8345Z" fill="#1a5232"/>
                <path d="M38.05 6.78335C40.5861 6.83731 40.5861 2.87505 38.05 2.92901C35.5215 2.87505 35.5215 6.83731 38.05 6.78335Z" fill="#1a5232"/>
                <path d="M40 13.4902H27.2884V18.3467H40V13.4902Z" fill="#1a5232"/>
                <path d="M40 36.6165H27.2884V41.4729H40V36.6165Z" fill="#1a5232"/>
                <path d="M40 19.2712H27.2884V24.1277H40V19.2712Z" fill="#1a5232"/>
                <path d="M40 30.8345H27.2884V35.6909H40V30.8345Z" fill="#1a5232"/>
                <path d="M40 25.0532H27.2884V29.9097H40V25.0532Z" fill="#1a5232"/>
              </svg>
            </span>
            <span class="mt-2">
              نظام إدارة المهام
            </span>
          </a>
        </div>
       
      </div>

     
    </div>
  </div> -->