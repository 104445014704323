/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiAuthenticationConfirmEmailGet } from '../fn/authentication/api-authentication-confirm-email-get';
import { ApiAuthenticationConfirmEmailGet$Params } from '../fn/authentication/api-authentication-confirm-email-get';
import { apiAuthenticationConfirmEmailGet$Plain } from '../fn/authentication/api-authentication-confirm-email-get-plain';
import { ApiAuthenticationConfirmEmailGet$Plain$Params } from '../fn/authentication/api-authentication-confirm-email-get-plain';
import { apiAuthenticationForgetPasswordConfirmationPost } from '../fn/authentication/api-authentication-forget-password-confirmation-post';
import { ApiAuthenticationForgetPasswordConfirmationPost$Params } from '../fn/authentication/api-authentication-forget-password-confirmation-post';
import { apiAuthenticationForgetPasswordConfirmationPost$Plain } from '../fn/authentication/api-authentication-forget-password-confirmation-post-plain';
import { ApiAuthenticationForgetPasswordConfirmationPost$Plain$Params } from '../fn/authentication/api-authentication-forget-password-confirmation-post-plain';
import { apiAuthenticationForgetPasswordPost } from '../fn/authentication/api-authentication-forget-password-post';
import { ApiAuthenticationForgetPasswordPost$Params } from '../fn/authentication/api-authentication-forget-password-post';
import { apiAuthenticationForgetPasswordPost$Plain } from '../fn/authentication/api-authentication-forget-password-post-plain';
import { ApiAuthenticationForgetPasswordPost$Plain$Params } from '../fn/authentication/api-authentication-forget-password-post-plain';
import { apiAuthenticationLoginPost } from '../fn/authentication/api-authentication-login-post';
import { ApiAuthenticationLoginPost$Params } from '../fn/authentication/api-authentication-login-post';
import { apiAuthenticationLoginPost$Plain } from '../fn/authentication/api-authentication-login-post-plain';
import { ApiAuthenticationLoginPost$Plain$Params } from '../fn/authentication/api-authentication-login-post-plain';
import { apiAuthenticationResendConfirmEmailGet } from '../fn/authentication/api-authentication-resend-confirm-email-get';
import { ApiAuthenticationResendConfirmEmailGet$Params } from '../fn/authentication/api-authentication-resend-confirm-email-get';
import { apiAuthenticationResendConfirmEmailGet$Plain } from '../fn/authentication/api-authentication-resend-confirm-email-get-plain';
import { ApiAuthenticationResendConfirmEmailGet$Plain$Params } from '../fn/authentication/api-authentication-resend-confirm-email-get-plain';
import { ConfirmEmailCommandResult } from '../models/confirm-email-command-result';
import { ForgotPasswordCommandResult } from '../models/forgot-password-command-result';
import { ForgotPasswordConfirmationCommandResult } from '../models/forgot-password-confirmation-command-result';
import { LoginCommandResult } from '../models/login-command-result';
import { SendConfirmEmailCommandResult } from '../models/send-confirm-email-command-result';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAuthenticationLoginPost()` */
  static readonly ApiAuthenticationLoginPostPath = '/api/Authentication/Login';

  /**
   * Logs in a user with the specified credentials.
   *
   * Here are some sample accounts you can use:
   * - Admin: `ElMofeed`, Password: `Am123@Am456`
   * - Student: `sad71653@gmail.com`, Password: `12345mM`
   * - Teacher: ` mrphysics50@gmail.com`, Password: `123456`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthenticationLoginPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationLoginPost$Plain$Response(params?: ApiAuthenticationLoginPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginCommandResult>> {
    return apiAuthenticationLoginPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Logs in a user with the specified credentials.
   *
   * Here are some sample accounts you can use:
   * - Admin: `ElMofeed`, Password: `Am123@Am456`
   * - Student: `sad71653@gmail.com`, Password: `12345mM`
   * - Teacher: ` mrphysics50@gmail.com`, Password: `123456`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuthenticationLoginPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationLoginPost$Plain(params?: ApiAuthenticationLoginPost$Plain$Params, context?: HttpContext): Observable<LoginCommandResult> {
    return this.apiAuthenticationLoginPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginCommandResult>): LoginCommandResult => r.body)
    );
  }

  /**
   * Logs in a user with the specified credentials.
   *
   * Here are some sample accounts you can use:
   * - Admin: `ElMofeed`, Password: `Am123@Am456`
   * - Student: `sad71653@gmail.com`, Password: `12345mM`
   * - Teacher: ` mrphysics50@gmail.com`, Password: `123456`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthenticationLoginPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationLoginPost$Response(params?: ApiAuthenticationLoginPost$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginCommandResult>> {
    return apiAuthenticationLoginPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Logs in a user with the specified credentials.
   *
   * Here are some sample accounts you can use:
   * - Admin: `ElMofeed`, Password: `Am123@Am456`
   * - Student: `sad71653@gmail.com`, Password: `12345mM`
   * - Teacher: ` mrphysics50@gmail.com`, Password: `123456`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuthenticationLoginPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationLoginPost(params?: ApiAuthenticationLoginPost$Params, context?: HttpContext): Observable<LoginCommandResult> {
    return this.apiAuthenticationLoginPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginCommandResult>): LoginCommandResult => r.body)
    );
  }

  /** Path part for operation `apiAuthenticationConfirmEmailGet()` */
  static readonly ApiAuthenticationConfirmEmailGetPath = '/api/Authentication/ConfirmEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthenticationConfirmEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthenticationConfirmEmailGet$Plain$Response(params: ApiAuthenticationConfirmEmailGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ConfirmEmailCommandResult>> {
    return apiAuthenticationConfirmEmailGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuthenticationConfirmEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthenticationConfirmEmailGet$Plain(params: ApiAuthenticationConfirmEmailGet$Plain$Params, context?: HttpContext): Observable<ConfirmEmailCommandResult> {
    return this.apiAuthenticationConfirmEmailGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfirmEmailCommandResult>): ConfirmEmailCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthenticationConfirmEmailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthenticationConfirmEmailGet$Response(params: ApiAuthenticationConfirmEmailGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ConfirmEmailCommandResult>> {
    return apiAuthenticationConfirmEmailGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuthenticationConfirmEmailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthenticationConfirmEmailGet(params: ApiAuthenticationConfirmEmailGet$Params, context?: HttpContext): Observable<ConfirmEmailCommandResult> {
    return this.apiAuthenticationConfirmEmailGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfirmEmailCommandResult>): ConfirmEmailCommandResult => r.body)
    );
  }

  /** Path part for operation `apiAuthenticationForgetPasswordConfirmationPost()` */
  static readonly ApiAuthenticationForgetPasswordConfirmationPostPath = '/api/Authentication/ForgetPasswordConfirmation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthenticationForgetPasswordConfirmationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationForgetPasswordConfirmationPost$Plain$Response(params?: ApiAuthenticationForgetPasswordConfirmationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ForgotPasswordConfirmationCommandResult>> {
    return apiAuthenticationForgetPasswordConfirmationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuthenticationForgetPasswordConfirmationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationForgetPasswordConfirmationPost$Plain(params?: ApiAuthenticationForgetPasswordConfirmationPost$Plain$Params, context?: HttpContext): Observable<ForgotPasswordConfirmationCommandResult> {
    return this.apiAuthenticationForgetPasswordConfirmationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ForgotPasswordConfirmationCommandResult>): ForgotPasswordConfirmationCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthenticationForgetPasswordConfirmationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationForgetPasswordConfirmationPost$Response(params?: ApiAuthenticationForgetPasswordConfirmationPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ForgotPasswordConfirmationCommandResult>> {
    return apiAuthenticationForgetPasswordConfirmationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuthenticationForgetPasswordConfirmationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationForgetPasswordConfirmationPost(params?: ApiAuthenticationForgetPasswordConfirmationPost$Params, context?: HttpContext): Observable<ForgotPasswordConfirmationCommandResult> {
    return this.apiAuthenticationForgetPasswordConfirmationPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ForgotPasswordConfirmationCommandResult>): ForgotPasswordConfirmationCommandResult => r.body)
    );
  }

  /** Path part for operation `apiAuthenticationForgetPasswordPost()` */
  static readonly ApiAuthenticationForgetPasswordPostPath = '/api/Authentication/ForgetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthenticationForgetPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationForgetPasswordPost$Plain$Response(params?: ApiAuthenticationForgetPasswordPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ForgotPasswordCommandResult>> {
    return apiAuthenticationForgetPasswordPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuthenticationForgetPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationForgetPasswordPost$Plain(params?: ApiAuthenticationForgetPasswordPost$Plain$Params, context?: HttpContext): Observable<ForgotPasswordCommandResult> {
    return this.apiAuthenticationForgetPasswordPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ForgotPasswordCommandResult>): ForgotPasswordCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthenticationForgetPasswordPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationForgetPasswordPost$Response(params?: ApiAuthenticationForgetPasswordPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ForgotPasswordCommandResult>> {
    return apiAuthenticationForgetPasswordPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuthenticationForgetPasswordPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAuthenticationForgetPasswordPost(params?: ApiAuthenticationForgetPasswordPost$Params, context?: HttpContext): Observable<ForgotPasswordCommandResult> {
    return this.apiAuthenticationForgetPasswordPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ForgotPasswordCommandResult>): ForgotPasswordCommandResult => r.body)
    );
  }

  /** Path part for operation `apiAuthenticationResendConfirmEmailGet()` */
  static readonly ApiAuthenticationResendConfirmEmailGetPath = '/api/Authentication/ResendConfirmEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthenticationResendConfirmEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthenticationResendConfirmEmailGet$Plain$Response(params: ApiAuthenticationResendConfirmEmailGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SendConfirmEmailCommandResult>> {
    return apiAuthenticationResendConfirmEmailGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuthenticationResendConfirmEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthenticationResendConfirmEmailGet$Plain(params: ApiAuthenticationResendConfirmEmailGet$Plain$Params, context?: HttpContext): Observable<SendConfirmEmailCommandResult> {
    return this.apiAuthenticationResendConfirmEmailGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SendConfirmEmailCommandResult>): SendConfirmEmailCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthenticationResendConfirmEmailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthenticationResendConfirmEmailGet$Response(params: ApiAuthenticationResendConfirmEmailGet$Params, context?: HttpContext): Observable<StrictHttpResponse<SendConfirmEmailCommandResult>> {
    return apiAuthenticationResendConfirmEmailGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuthenticationResendConfirmEmailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthenticationResendConfirmEmailGet(params: ApiAuthenticationResendConfirmEmailGet$Params, context?: HttpContext): Observable<SendConfirmEmailCommandResult> {
    return this.apiAuthenticationResendConfirmEmailGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<SendConfirmEmailCommandResult>): SendConfirmEmailCommandResult => r.body)
    );
  }

}
