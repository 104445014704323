/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiTExamCreateExamPost } from '../fn/t-exam/api-t-exam-create-exam-post';
import { ApiTExamCreateExamPost$Params } from '../fn/t-exam/api-t-exam-create-exam-post';
import { apiTExamCreateExamPost$Plain } from '../fn/t-exam/api-t-exam-create-exam-post-plain';
import { ApiTExamCreateExamPost$Plain$Params } from '../fn/t-exam/api-t-exam-create-exam-post-plain';
import { apiTExamCreateTExamQuestionPost } from '../fn/t-exam/api-t-exam-create-t-exam-question-post';
import { ApiTExamCreateTExamQuestionPost$Params } from '../fn/t-exam/api-t-exam-create-t-exam-question-post';
import { apiTExamCreateTExamQuestionPost$Plain } from '../fn/t-exam/api-t-exam-create-t-exam-question-post-plain';
import { ApiTExamCreateTExamQuestionPost$Plain$Params } from '../fn/t-exam/api-t-exam-create-t-exam-question-post-plain';
import { apiTExamDeleteExamDelete } from '../fn/t-exam/api-t-exam-delete-exam-delete';
import { ApiTExamDeleteExamDelete$Params } from '../fn/t-exam/api-t-exam-delete-exam-delete';
import { apiTExamDeleteExamDelete$Plain } from '../fn/t-exam/api-t-exam-delete-exam-delete-plain';
import { ApiTExamDeleteExamDelete$Plain$Params } from '../fn/t-exam/api-t-exam-delete-exam-delete-plain';
import { apiTExamDeleteTExamQuestionDelete } from '../fn/t-exam/api-t-exam-delete-t-exam-question-delete';
import { ApiTExamDeleteTExamQuestionDelete$Params } from '../fn/t-exam/api-t-exam-delete-t-exam-question-delete';
import { apiTExamDeleteTExamQuestionDelete$Plain } from '../fn/t-exam/api-t-exam-delete-t-exam-question-delete-plain';
import { ApiTExamDeleteTExamQuestionDelete$Plain$Params } from '../fn/t-exam/api-t-exam-delete-t-exam-question-delete-plain';
import { apiTExamGetExamPost } from '../fn/t-exam/api-t-exam-get-exam-post';
import { ApiTExamGetExamPost$Params } from '../fn/t-exam/api-t-exam-get-exam-post';
import { apiTExamGetExamPost$Plain } from '../fn/t-exam/api-t-exam-get-exam-post-plain';
import { ApiTExamGetExamPost$Plain$Params } from '../fn/t-exam/api-t-exam-get-exam-post-plain';
import { apiTExamGetExamsPost } from '../fn/t-exam/api-t-exam-get-exams-post';
import { ApiTExamGetExamsPost$Params } from '../fn/t-exam/api-t-exam-get-exams-post';
import { apiTExamGetExamsPost$Plain } from '../fn/t-exam/api-t-exam-get-exams-post-plain';
import { ApiTExamGetExamsPost$Plain$Params } from '../fn/t-exam/api-t-exam-get-exams-post-plain';
import { apiTExamGetStudentAnsweredExamPost } from '../fn/t-exam/api-t-exam-get-student-answered-exam-post';
import { ApiTExamGetStudentAnsweredExamPost$Params } from '../fn/t-exam/api-t-exam-get-student-answered-exam-post';
import { apiTExamGetStudentAnsweredExamPost$Plain } from '../fn/t-exam/api-t-exam-get-student-answered-exam-post-plain';
import { ApiTExamGetStudentAnsweredExamPost$Plain$Params } from '../fn/t-exam/api-t-exam-get-student-answered-exam-post-plain';
import { apiTExamGetStudentAnsweredExamsPost } from '../fn/t-exam/api-t-exam-get-student-answered-exams-post';
import { ApiTExamGetStudentAnsweredExamsPost$Params } from '../fn/t-exam/api-t-exam-get-student-answered-exams-post';
import { apiTExamGetStudentAnsweredExamsPost$Plain } from '../fn/t-exam/api-t-exam-get-student-answered-exams-post-plain';
import { ApiTExamGetStudentAnsweredExamsPost$Plain$Params } from '../fn/t-exam/api-t-exam-get-student-answered-exams-post-plain';
import { apiTExamRepeatExamTimeInsurePatch } from '../fn/t-exam/api-t-exam-repeat-exam-time-insure-patch';
import { ApiTExamRepeatExamTimeInsurePatch$Params } from '../fn/t-exam/api-t-exam-repeat-exam-time-insure-patch';
import { apiTExamRepeatExamTimeInsurePatch$Plain } from '../fn/t-exam/api-t-exam-repeat-exam-time-insure-patch-plain';
import { ApiTExamRepeatExamTimeInsurePatch$Plain$Params } from '../fn/t-exam/api-t-exam-repeat-exam-time-insure-patch-plain';
import { apiTExamStartSolvingPatch } from '../fn/t-exam/api-t-exam-start-solving-patch';
import { ApiTExamStartSolvingPatch$Params } from '../fn/t-exam/api-t-exam-start-solving-patch';
import { apiTExamStartSolvingPatch$Plain } from '../fn/t-exam/api-t-exam-start-solving-patch-plain';
import { ApiTExamStartSolvingPatch$Plain$Params } from '../fn/t-exam/api-t-exam-start-solving-patch-plain';
import { apiTExamStudentSubmitExamPatch } from '../fn/t-exam/api-t-exam-student-submit-exam-patch';
import { ApiTExamStudentSubmitExamPatch$Params } from '../fn/t-exam/api-t-exam-student-submit-exam-patch';
import { apiTExamStudentSubmitExamPatch$Plain } from '../fn/t-exam/api-t-exam-student-submit-exam-patch-plain';
import { ApiTExamStudentSubmitExamPatch$Plain$Params } from '../fn/t-exam/api-t-exam-student-submit-exam-patch-plain';
import { apiTExamUpdateExamPatch } from '../fn/t-exam/api-t-exam-update-exam-patch';
import { ApiTExamUpdateExamPatch$Params } from '../fn/t-exam/api-t-exam-update-exam-patch';
import { apiTExamUpdateExamPatch$Plain } from '../fn/t-exam/api-t-exam-update-exam-patch-plain';
import { ApiTExamUpdateExamPatch$Plain$Params } from '../fn/t-exam/api-t-exam-update-exam-patch-plain';
import { apiTExamUpdateTExamQuestionPatch } from '../fn/t-exam/api-t-exam-update-t-exam-question-patch';
import { ApiTExamUpdateTExamQuestionPatch$Params } from '../fn/t-exam/api-t-exam-update-t-exam-question-patch';
import { apiTExamUpdateTExamQuestionPatch$Plain } from '../fn/t-exam/api-t-exam-update-t-exam-question-patch-plain';
import { ApiTExamUpdateTExamQuestionPatch$Plain$Params } from '../fn/t-exam/api-t-exam-update-t-exam-question-patch-plain';
import { CreateTExamCommandResult } from '../models/create-t-exam-command-result';
import { CreateTExamQuestionCommandResult } from '../models/create-t-exam-question-command-result';
import { DeleteTExamCommandResult } from '../models/delete-t-exam-command-result';
import { DeleteTExamQuestionCommandResult } from '../models/delete-t-exam-question-command-result';
import { GetExamQueryResult } from '../models/get-exam-query-result';
import { GetExamsQueryResult } from '../models/get-exams-query-result';
import { GetStudentAnsweredExamQueryResult } from '../models/get-student-answered-exam-query-result';
import { GetStudentAnsweredExamsQueryResult } from '../models/get-student-answered-exams-query-result';
import { RepeatExamTimeInsureCommandResult } from '../models/repeat-exam-time-insure-command-result';
import { StartSolvingCommandResult } from '../models/start-solving-command-result';
import { SubmitExamCommandResult } from '../models/submit-exam-command-result';
import { UpdateTExamCommandResult } from '../models/update-t-exam-command-result';
import { UpdateTExamQuestionCommandResult } from '../models/update-t-exam-question-command-result';

@Injectable({ providedIn: 'root' })
export class TExamService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiTExamGetExamPost()` */
  static readonly ApiTExamGetExamPostPath = '/api/TExam/GetExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamGetExamPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamGetExamPost$Plain$Response(params?: ApiTExamGetExamPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamQueryResult>> {
    return apiTExamGetExamPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamGetExamPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamGetExamPost$Plain(params?: ApiTExamGetExamPost$Plain$Params, context?: HttpContext): Observable<GetExamQueryResult> {
    return this.apiTExamGetExamPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamQueryResult>): GetExamQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamGetExamPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamGetExamPost$Response(params?: ApiTExamGetExamPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamQueryResult>> {
    return apiTExamGetExamPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamGetExamPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamGetExamPost(params?: ApiTExamGetExamPost$Params, context?: HttpContext): Observable<GetExamQueryResult> {
    return this.apiTExamGetExamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamQueryResult>): GetExamQueryResult => r.body)
    );
  }

  /** Path part for operation `apiTExamGetExamsPost()` */
  static readonly ApiTExamGetExamsPostPath = '/api/TExam/GetExams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamGetExamsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamGetExamsPost$Plain$Response(params?: ApiTExamGetExamsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamsQueryResult>> {
    return apiTExamGetExamsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamGetExamsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamGetExamsPost$Plain(params?: ApiTExamGetExamsPost$Plain$Params, context?: HttpContext): Observable<GetExamsQueryResult> {
    return this.apiTExamGetExamsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamsQueryResult>): GetExamsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamGetExamsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamGetExamsPost$Response(params?: ApiTExamGetExamsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamsQueryResult>> {
    return apiTExamGetExamsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamGetExamsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamGetExamsPost(params?: ApiTExamGetExamsPost$Params, context?: HttpContext): Observable<GetExamsQueryResult> {
    return this.apiTExamGetExamsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamsQueryResult>): GetExamsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiTExamCreateExamPost()` */
  static readonly ApiTExamCreateExamPostPath = '/api/TExam/CreateExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamCreateExamPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamCreateExamPost$Plain$Response(params?: ApiTExamCreateExamPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTExamCommandResult>> {
    return apiTExamCreateExamPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamCreateExamPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamCreateExamPost$Plain(params?: ApiTExamCreateExamPost$Plain$Params, context?: HttpContext): Observable<CreateTExamCommandResult> {
    return this.apiTExamCreateExamPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTExamCommandResult>): CreateTExamCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamCreateExamPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamCreateExamPost$Response(params?: ApiTExamCreateExamPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTExamCommandResult>> {
    return apiTExamCreateExamPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamCreateExamPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamCreateExamPost(params?: ApiTExamCreateExamPost$Params, context?: HttpContext): Observable<CreateTExamCommandResult> {
    return this.apiTExamCreateExamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTExamCommandResult>): CreateTExamCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTExamUpdateExamPatch()` */
  static readonly ApiTExamUpdateExamPatchPath = '/api/TExam/UpdateExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamUpdateExamPatch$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamUpdateExamPatch$Plain$Response(params?: ApiTExamUpdateExamPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamCommandResult>> {
    return apiTExamUpdateExamPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamUpdateExamPatch$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamUpdateExamPatch$Plain(params?: ApiTExamUpdateExamPatch$Plain$Params, context?: HttpContext): Observable<UpdateTExamCommandResult> {
    return this.apiTExamUpdateExamPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTExamCommandResult>): UpdateTExamCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamUpdateExamPatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamUpdateExamPatch$Response(params?: ApiTExamUpdateExamPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamCommandResult>> {
    return apiTExamUpdateExamPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamUpdateExamPatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamUpdateExamPatch(params?: ApiTExamUpdateExamPatch$Params, context?: HttpContext): Observable<UpdateTExamCommandResult> {
    return this.apiTExamUpdateExamPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTExamCommandResult>): UpdateTExamCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTExamDeleteExamDelete()` */
  static readonly ApiTExamDeleteExamDeletePath = '/api/TExam/DeleteExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamDeleteExamDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamDeleteExamDelete$Plain$Response(params?: ApiTExamDeleteExamDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTExamCommandResult>> {
    return apiTExamDeleteExamDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamDeleteExamDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamDeleteExamDelete$Plain(params?: ApiTExamDeleteExamDelete$Plain$Params, context?: HttpContext): Observable<DeleteTExamCommandResult> {
    return this.apiTExamDeleteExamDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTExamCommandResult>): DeleteTExamCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamDeleteExamDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamDeleteExamDelete$Response(params?: ApiTExamDeleteExamDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTExamCommandResult>> {
    return apiTExamDeleteExamDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamDeleteExamDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTExamDeleteExamDelete(params?: ApiTExamDeleteExamDelete$Params, context?: HttpContext): Observable<DeleteTExamCommandResult> {
    return this.apiTExamDeleteExamDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTExamCommandResult>): DeleteTExamCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTExamCreateTExamQuestionPost()` */
  static readonly ApiTExamCreateTExamQuestionPostPath = '/api/TExam/CreateTExamQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamCreateTExamQuestionPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamCreateTExamQuestionPost$Plain$Response(params?: ApiTExamCreateTExamQuestionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTExamQuestionCommandResult>> {
    return apiTExamCreateTExamQuestionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamCreateTExamQuestionPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamCreateTExamQuestionPost$Plain(params?: ApiTExamCreateTExamQuestionPost$Plain$Params, context?: HttpContext): Observable<CreateTExamQuestionCommandResult> {
    return this.apiTExamCreateTExamQuestionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTExamQuestionCommandResult>): CreateTExamQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamCreateTExamQuestionPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamCreateTExamQuestionPost$Response(params?: ApiTExamCreateTExamQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTExamQuestionCommandResult>> {
    return apiTExamCreateTExamQuestionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamCreateTExamQuestionPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamCreateTExamQuestionPost(params?: ApiTExamCreateTExamQuestionPost$Params, context?: HttpContext): Observable<CreateTExamQuestionCommandResult> {
    return this.apiTExamCreateTExamQuestionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTExamQuestionCommandResult>): CreateTExamQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTExamUpdateTExamQuestionPatch()` */
  static readonly ApiTExamUpdateTExamQuestionPatchPath = '/api/TExam/UpdateTExamQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamUpdateTExamQuestionPatch$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamUpdateTExamQuestionPatch$Plain$Response(params?: ApiTExamUpdateTExamQuestionPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamQuestionCommandResult>> {
    return apiTExamUpdateTExamQuestionPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamUpdateTExamQuestionPatch$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamUpdateTExamQuestionPatch$Plain(params?: ApiTExamUpdateTExamQuestionPatch$Plain$Params, context?: HttpContext): Observable<UpdateTExamQuestionCommandResult> {
    return this.apiTExamUpdateTExamQuestionPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTExamQuestionCommandResult>): UpdateTExamQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamUpdateTExamQuestionPatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamUpdateTExamQuestionPatch$Response(params?: ApiTExamUpdateTExamQuestionPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamQuestionCommandResult>> {
    return apiTExamUpdateTExamQuestionPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamUpdateTExamQuestionPatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamUpdateTExamQuestionPatch(params?: ApiTExamUpdateTExamQuestionPatch$Params, context?: HttpContext): Observable<UpdateTExamQuestionCommandResult> {
    return this.apiTExamUpdateTExamQuestionPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTExamQuestionCommandResult>): UpdateTExamQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTExamDeleteTExamQuestionDelete()` */
  static readonly ApiTExamDeleteTExamQuestionDeletePath = '/api/TExam/DeleteTExamQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamDeleteTExamQuestionDelete$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamDeleteTExamQuestionDelete$Plain$Response(params?: ApiTExamDeleteTExamQuestionDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTExamQuestionCommandResult>> {
    return apiTExamDeleteTExamQuestionDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamDeleteTExamQuestionDelete$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamDeleteTExamQuestionDelete$Plain(params?: ApiTExamDeleteTExamQuestionDelete$Plain$Params, context?: HttpContext): Observable<DeleteTExamQuestionCommandResult> {
    return this.apiTExamDeleteTExamQuestionDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTExamQuestionCommandResult>): DeleteTExamQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamDeleteTExamQuestionDelete()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamDeleteTExamQuestionDelete$Response(params?: ApiTExamDeleteTExamQuestionDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTExamQuestionCommandResult>> {
    return apiTExamDeleteTExamQuestionDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamDeleteTExamQuestionDelete$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamDeleteTExamQuestionDelete(params?: ApiTExamDeleteTExamQuestionDelete$Params, context?: HttpContext): Observable<DeleteTExamQuestionCommandResult> {
    return this.apiTExamDeleteTExamQuestionDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTExamQuestionCommandResult>): DeleteTExamQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTExamStudentSubmitExamPatch()` */
  static readonly ApiTExamStudentSubmitExamPatchPath = '/api/TExam/StudentSubmitExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamStudentSubmitExamPatch$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamStudentSubmitExamPatch$Plain$Response(params?: ApiTExamStudentSubmitExamPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SubmitExamCommandResult>> {
    return apiTExamStudentSubmitExamPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamStudentSubmitExamPatch$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamStudentSubmitExamPatch$Plain(params?: ApiTExamStudentSubmitExamPatch$Plain$Params, context?: HttpContext): Observable<SubmitExamCommandResult> {
    return this.apiTExamStudentSubmitExamPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubmitExamCommandResult>): SubmitExamCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamStudentSubmitExamPatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamStudentSubmitExamPatch$Response(params?: ApiTExamStudentSubmitExamPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<SubmitExamCommandResult>> {
    return apiTExamStudentSubmitExamPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamStudentSubmitExamPatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamStudentSubmitExamPatch(params?: ApiTExamStudentSubmitExamPatch$Params, context?: HttpContext): Observable<SubmitExamCommandResult> {
    return this.apiTExamStudentSubmitExamPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubmitExamCommandResult>): SubmitExamCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTExamStartSolvingPatch()` */
  static readonly ApiTExamStartSolvingPatchPath = '/api/TExam/StartSolving';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamStartSolvingPatch$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamStartSolvingPatch$Plain$Response(params?: ApiTExamStartSolvingPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StartSolvingCommandResult>> {
    return apiTExamStartSolvingPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamStartSolvingPatch$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamStartSolvingPatch$Plain(params?: ApiTExamStartSolvingPatch$Plain$Params, context?: HttpContext): Observable<StartSolvingCommandResult> {
    return this.apiTExamStartSolvingPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StartSolvingCommandResult>): StartSolvingCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamStartSolvingPatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamStartSolvingPatch$Response(params?: ApiTExamStartSolvingPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<StartSolvingCommandResult>> {
    return apiTExamStartSolvingPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamStartSolvingPatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamStartSolvingPatch(params?: ApiTExamStartSolvingPatch$Params, context?: HttpContext): Observable<StartSolvingCommandResult> {
    return this.apiTExamStartSolvingPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<StartSolvingCommandResult>): StartSolvingCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTExamRepeatExamTimeInsurePatch()` */
  static readonly ApiTExamRepeatExamTimeInsurePatchPath = '/api/TExam/RepeatExamTimeInsure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamRepeatExamTimeInsurePatch$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamRepeatExamTimeInsurePatch$Plain$Response(params?: ApiTExamRepeatExamTimeInsurePatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RepeatExamTimeInsureCommandResult>> {
    return apiTExamRepeatExamTimeInsurePatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamRepeatExamTimeInsurePatch$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamRepeatExamTimeInsurePatch$Plain(params?: ApiTExamRepeatExamTimeInsurePatch$Plain$Params, context?: HttpContext): Observable<RepeatExamTimeInsureCommandResult> {
    return this.apiTExamRepeatExamTimeInsurePatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RepeatExamTimeInsureCommandResult>): RepeatExamTimeInsureCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamRepeatExamTimeInsurePatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamRepeatExamTimeInsurePatch$Response(params?: ApiTExamRepeatExamTimeInsurePatch$Params, context?: HttpContext): Observable<StrictHttpResponse<RepeatExamTimeInsureCommandResult>> {
    return apiTExamRepeatExamTimeInsurePatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamRepeatExamTimeInsurePatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamRepeatExamTimeInsurePatch(params?: ApiTExamRepeatExamTimeInsurePatch$Params, context?: HttpContext): Observable<RepeatExamTimeInsureCommandResult> {
    return this.apiTExamRepeatExamTimeInsurePatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<RepeatExamTimeInsureCommandResult>): RepeatExamTimeInsureCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTExamGetStudentAnsweredExamsPost()` */
  static readonly ApiTExamGetStudentAnsweredExamsPostPath = '/api/TExam/GetStudentAnsweredExams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamGetStudentAnsweredExamsPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamGetStudentAnsweredExamsPost$Plain$Response(params?: ApiTExamGetStudentAnsweredExamsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentAnsweredExamsQueryResult>> {
    return apiTExamGetStudentAnsweredExamsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamGetStudentAnsweredExamsPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamGetStudentAnsweredExamsPost$Plain(params?: ApiTExamGetStudentAnsweredExamsPost$Plain$Params, context?: HttpContext): Observable<GetStudentAnsweredExamsQueryResult> {
    return this.apiTExamGetStudentAnsweredExamsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentAnsweredExamsQueryResult>): GetStudentAnsweredExamsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamGetStudentAnsweredExamsPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamGetStudentAnsweredExamsPost$Response(params?: ApiTExamGetStudentAnsweredExamsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentAnsweredExamsQueryResult>> {
    return apiTExamGetStudentAnsweredExamsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamGetStudentAnsweredExamsPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamGetStudentAnsweredExamsPost(params?: ApiTExamGetStudentAnsweredExamsPost$Params, context?: HttpContext): Observable<GetStudentAnsweredExamsQueryResult> {
    return this.apiTExamGetStudentAnsweredExamsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentAnsweredExamsQueryResult>): GetStudentAnsweredExamsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiTExamGetStudentAnsweredExamPost()` */
  static readonly ApiTExamGetStudentAnsweredExamPostPath = '/api/TExam/GetStudentAnsweredExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamGetStudentAnsweredExamPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamGetStudentAnsweredExamPost$Plain$Response(params?: ApiTExamGetStudentAnsweredExamPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentAnsweredExamQueryResult>> {
    return apiTExamGetStudentAnsweredExamPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamGetStudentAnsweredExamPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamGetStudentAnsweredExamPost$Plain(params?: ApiTExamGetStudentAnsweredExamPost$Plain$Params, context?: HttpContext): Observable<GetStudentAnsweredExamQueryResult> {
    return this.apiTExamGetStudentAnsweredExamPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentAnsweredExamQueryResult>): GetStudentAnsweredExamQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTExamGetStudentAnsweredExamPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamGetStudentAnsweredExamPost$Response(params?: ApiTExamGetStudentAnsweredExamPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentAnsweredExamQueryResult>> {
    return apiTExamGetStudentAnsweredExamPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTExamGetStudentAnsweredExamPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTExamGetStudentAnsweredExamPost(params?: ApiTExamGetStudentAnsweredExamPost$Params, context?: HttpContext): Observable<GetStudentAnsweredExamQueryResult> {
    return this.apiTExamGetStudentAnsweredExamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentAnsweredExamQueryResult>): GetStudentAnsweredExamQueryResult => r.body)
    );
  }

}
