/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiTalentedStudentsCreateTalentedStudentPost } from '../fn/talented-students/api-talented-students-create-talented-student-post';
import { ApiTalentedStudentsCreateTalentedStudentPost$Params } from '../fn/talented-students/api-talented-students-create-talented-student-post';
import { apiTalentedStudentsCreateTalentedStudentPost$Plain } from '../fn/talented-students/api-talented-students-create-talented-student-post-plain';
import { ApiTalentedStudentsCreateTalentedStudentPost$Plain$Params } from '../fn/talented-students/api-talented-students-create-talented-student-post-plain';
import { apiTalentedStudentsDeleteTalentedStudentPost } from '../fn/talented-students/api-talented-students-delete-talented-student-post';
import { ApiTalentedStudentsDeleteTalentedStudentPost$Params } from '../fn/talented-students/api-talented-students-delete-talented-student-post';
import { apiTalentedStudentsDeleteTalentedStudentPost$Plain } from '../fn/talented-students/api-talented-students-delete-talented-student-post-plain';
import { ApiTalentedStudentsDeleteTalentedStudentPost$Plain$Params } from '../fn/talented-students/api-talented-students-delete-talented-student-post-plain';
import { apiTalentedStudentsGetTalentedStudentPost } from '../fn/talented-students/api-talented-students-get-talented-student-post';
import { ApiTalentedStudentsGetTalentedStudentPost$Params } from '../fn/talented-students/api-talented-students-get-talented-student-post';
import { apiTalentedStudentsGetTalentedStudentPost$Plain } from '../fn/talented-students/api-talented-students-get-talented-student-post-plain';
import { ApiTalentedStudentsGetTalentedStudentPost$Plain$Params } from '../fn/talented-students/api-talented-students-get-talented-student-post-plain';
import { apiTalentedStudentsGetTalentedStudentsPost } from '../fn/talented-students/api-talented-students-get-talented-students-post';
import { ApiTalentedStudentsGetTalentedStudentsPost$Params } from '../fn/talented-students/api-talented-students-get-talented-students-post';
import { apiTalentedStudentsGetTalentedStudentsPost$Plain } from '../fn/talented-students/api-talented-students-get-talented-students-post-plain';
import { ApiTalentedStudentsGetTalentedStudentsPost$Plain$Params } from '../fn/talented-students/api-talented-students-get-talented-students-post-plain';
import { apiTalentedStudentsUpdateTalentedStudentPost } from '../fn/talented-students/api-talented-students-update-talented-student-post';
import { ApiTalentedStudentsUpdateTalentedStudentPost$Params } from '../fn/talented-students/api-talented-students-update-talented-student-post';
import { apiTalentedStudentsUpdateTalentedStudentPost$Plain } from '../fn/talented-students/api-talented-students-update-talented-student-post-plain';
import { ApiTalentedStudentsUpdateTalentedStudentPost$Plain$Params } from '../fn/talented-students/api-talented-students-update-talented-student-post-plain';
import { CreateTalentStudentCommandResult } from '../models/create-talent-student-command-result';
import { DeleteTalentedStudentCommandResult } from '../models/delete-talented-student-command-result';
import { GetTalentedStudentQueryResult } from '../models/get-talented-student-query-result';
import { GetTalentedStudentsQueryResult } from '../models/get-talented-students-query-result';
import { UpdateTalentedStudentCommandResult } from '../models/update-talented-student-command-result';

@Injectable({ providedIn: 'root' })
export class TalentedStudentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiTalentedStudentsGetTalentedStudentPost()` */
  static readonly ApiTalentedStudentsGetTalentedStudentPostPath = '/api/TalentedStudents/GetTalentedStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTalentedStudentsGetTalentedStudentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsGetTalentedStudentPost$Plain$Response(params?: ApiTalentedStudentsGetTalentedStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTalentedStudentQueryResult>> {
    return apiTalentedStudentsGetTalentedStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTalentedStudentsGetTalentedStudentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsGetTalentedStudentPost$Plain(params?: ApiTalentedStudentsGetTalentedStudentPost$Plain$Params, context?: HttpContext): Observable<GetTalentedStudentQueryResult> {
    return this.apiTalentedStudentsGetTalentedStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTalentedStudentQueryResult>): GetTalentedStudentQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTalentedStudentsGetTalentedStudentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsGetTalentedStudentPost$Response(params?: ApiTalentedStudentsGetTalentedStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTalentedStudentQueryResult>> {
    return apiTalentedStudentsGetTalentedStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTalentedStudentsGetTalentedStudentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsGetTalentedStudentPost(params?: ApiTalentedStudentsGetTalentedStudentPost$Params, context?: HttpContext): Observable<GetTalentedStudentQueryResult> {
    return this.apiTalentedStudentsGetTalentedStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTalentedStudentQueryResult>): GetTalentedStudentQueryResult => r.body)
    );
  }

  /** Path part for operation `apiTalentedStudentsGetTalentedStudentsPost()` */
  static readonly ApiTalentedStudentsGetTalentedStudentsPostPath = '/api/TalentedStudents/GetTalentedStudents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTalentedStudentsGetTalentedStudentsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsGetTalentedStudentsPost$Plain$Response(params?: ApiTalentedStudentsGetTalentedStudentsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTalentedStudentsQueryResult>> {
    return apiTalentedStudentsGetTalentedStudentsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTalentedStudentsGetTalentedStudentsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsGetTalentedStudentsPost$Plain(params?: ApiTalentedStudentsGetTalentedStudentsPost$Plain$Params, context?: HttpContext): Observable<GetTalentedStudentsQueryResult> {
    return this.apiTalentedStudentsGetTalentedStudentsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTalentedStudentsQueryResult>): GetTalentedStudentsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTalentedStudentsGetTalentedStudentsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsGetTalentedStudentsPost$Response(params?: ApiTalentedStudentsGetTalentedStudentsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTalentedStudentsQueryResult>> {
    return apiTalentedStudentsGetTalentedStudentsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTalentedStudentsGetTalentedStudentsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsGetTalentedStudentsPost(params?: ApiTalentedStudentsGetTalentedStudentsPost$Params, context?: HttpContext): Observable<GetTalentedStudentsQueryResult> {
    return this.apiTalentedStudentsGetTalentedStudentsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTalentedStudentsQueryResult>): GetTalentedStudentsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiTalentedStudentsCreateTalentedStudentPost()` */
  static readonly ApiTalentedStudentsCreateTalentedStudentPostPath = '/api/TalentedStudents/CreateTalentedStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTalentedStudentsCreateTalentedStudentPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTalentedStudentsCreateTalentedStudentPost$Plain$Response(params?: ApiTalentedStudentsCreateTalentedStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTalentStudentCommandResult>> {
    return apiTalentedStudentsCreateTalentedStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTalentedStudentsCreateTalentedStudentPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTalentedStudentsCreateTalentedStudentPost$Plain(params?: ApiTalentedStudentsCreateTalentedStudentPost$Plain$Params, context?: HttpContext): Observable<CreateTalentStudentCommandResult> {
    return this.apiTalentedStudentsCreateTalentedStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTalentStudentCommandResult>): CreateTalentStudentCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTalentedStudentsCreateTalentedStudentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTalentedStudentsCreateTalentedStudentPost$Response(params?: ApiTalentedStudentsCreateTalentedStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTalentStudentCommandResult>> {
    return apiTalentedStudentsCreateTalentedStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTalentedStudentsCreateTalentedStudentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTalentedStudentsCreateTalentedStudentPost(params?: ApiTalentedStudentsCreateTalentedStudentPost$Params, context?: HttpContext): Observable<CreateTalentStudentCommandResult> {
    return this.apiTalentedStudentsCreateTalentedStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTalentStudentCommandResult>): CreateTalentStudentCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTalentedStudentsUpdateTalentedStudentPost()` */
  static readonly ApiTalentedStudentsUpdateTalentedStudentPostPath = '/api/TalentedStudents/UpdateTalentedStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTalentedStudentsUpdateTalentedStudentPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTalentedStudentsUpdateTalentedStudentPost$Plain$Response(params?: ApiTalentedStudentsUpdateTalentedStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTalentedStudentCommandResult>> {
    return apiTalentedStudentsUpdateTalentedStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTalentedStudentsUpdateTalentedStudentPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTalentedStudentsUpdateTalentedStudentPost$Plain(params?: ApiTalentedStudentsUpdateTalentedStudentPost$Plain$Params, context?: HttpContext): Observable<UpdateTalentedStudentCommandResult> {
    return this.apiTalentedStudentsUpdateTalentedStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTalentedStudentCommandResult>): UpdateTalentedStudentCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTalentedStudentsUpdateTalentedStudentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTalentedStudentsUpdateTalentedStudentPost$Response(params?: ApiTalentedStudentsUpdateTalentedStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTalentedStudentCommandResult>> {
    return apiTalentedStudentsUpdateTalentedStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTalentedStudentsUpdateTalentedStudentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTalentedStudentsUpdateTalentedStudentPost(params?: ApiTalentedStudentsUpdateTalentedStudentPost$Params, context?: HttpContext): Observable<UpdateTalentedStudentCommandResult> {
    return this.apiTalentedStudentsUpdateTalentedStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTalentedStudentCommandResult>): UpdateTalentedStudentCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTalentedStudentsDeleteTalentedStudentPost()` */
  static readonly ApiTalentedStudentsDeleteTalentedStudentPostPath = '/api/TalentedStudents/DeleteTalentedStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTalentedStudentsDeleteTalentedStudentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsDeleteTalentedStudentPost$Plain$Response(params?: ApiTalentedStudentsDeleteTalentedStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTalentedStudentCommandResult>> {
    return apiTalentedStudentsDeleteTalentedStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTalentedStudentsDeleteTalentedStudentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsDeleteTalentedStudentPost$Plain(params?: ApiTalentedStudentsDeleteTalentedStudentPost$Plain$Params, context?: HttpContext): Observable<DeleteTalentedStudentCommandResult> {
    return this.apiTalentedStudentsDeleteTalentedStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTalentedStudentCommandResult>): DeleteTalentedStudentCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTalentedStudentsDeleteTalentedStudentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsDeleteTalentedStudentPost$Response(params?: ApiTalentedStudentsDeleteTalentedStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTalentedStudentCommandResult>> {
    return apiTalentedStudentsDeleteTalentedStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTalentedStudentsDeleteTalentedStudentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTalentedStudentsDeleteTalentedStudentPost(params?: ApiTalentedStudentsDeleteTalentedStudentPost$Params, context?: HttpContext): Observable<DeleteTalentedStudentCommandResult> {
    return this.apiTalentedStudentsDeleteTalentedStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTalentedStudentCommandResult>): DeleteTalentedStudentCommandResult => r.body)
    );
  }

}
