<header id="page-topbar">
  <div class="navbar-header  border-bottom border-light border-2">
    <div class="d-flex  justify-content-between align-items-center px-3 px-lg-4 gap-2">


      <a style="height: auto;" class="lh-1 p-0 d-flex align-items-center gap-2 noti-icon" id="page-header-notifications-dropdown"
        (click)="toggleMobileMenu($event)">
        <span class="notif_circle border-none border-lg-1">
          <i class="isax-menu-1  text-primary fs-5"></i>
        </span>
        <span class="d-inline d-lg-none">
          القائمــة
        </span>
      </a>

      <!-- LOGO -->
      <div class="navbar-brand-box d-inline-block d-lg-none">
        <a routerLink="/student" class="logo logo-dark">
          <span class="logo-sm">
            <img [src]="logos.dark_sm" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img [src]="logos.dark" alt="" height="30">
          </span>
        </a>

        <a routerLink="/student" class="logo logo-light">
          <span class="logo-sm">
            <img [src]="logos.light_sm" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img [src]="logos.light" alt="" height="30">
          </span>
        </a>
      </div>




      <!-- <sahely-notifications class="d-inline-block align-middle align-self-center"></sahely-notifications> -->




    </div>

    <div class="d-flex align-items-center gap-2">


      <div class="dropdown d-none d-lg-inline-block ">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

     
      <!-- ?? user -->
      <mg-account-dropdown></mg-account-dropdown>
      <!-- <div>
        <button routerLink="/subjects" class="btn rounded-pill px-4 btn-sm btn-primary">
          مناهج المفيــد
        </button>
      </div> -->



    </div>
  </div>
</header>