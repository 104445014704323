/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiLessonCreateLessonPost } from '../fn/lesson/api-lesson-create-lesson-post';
import { ApiLessonCreateLessonPost$Params } from '../fn/lesson/api-lesson-create-lesson-post';
import { apiLessonCreateLessonPost$Plain } from '../fn/lesson/api-lesson-create-lesson-post-plain';
import { ApiLessonCreateLessonPost$Plain$Params } from '../fn/lesson/api-lesson-create-lesson-post-plain';
import { apiLessonCreateStudentLessonCompletionPost } from '../fn/lesson/api-lesson-create-student-lesson-completion-post';
import { ApiLessonCreateStudentLessonCompletionPost$Params } from '../fn/lesson/api-lesson-create-student-lesson-completion-post';
import { apiLessonCreateStudentLessonCompletionPost$Plain } from '../fn/lesson/api-lesson-create-student-lesson-completion-post-plain';
import { ApiLessonCreateStudentLessonCompletionPost$Plain$Params } from '../fn/lesson/api-lesson-create-student-lesson-completion-post-plain';
import { apiLessonDeleteLessonPost } from '../fn/lesson/api-lesson-delete-lesson-post';
import { ApiLessonDeleteLessonPost$Params } from '../fn/lesson/api-lesson-delete-lesson-post';
import { apiLessonDeleteLessonPost$Plain } from '../fn/lesson/api-lesson-delete-lesson-post-plain';
import { ApiLessonDeleteLessonPost$Plain$Params } from '../fn/lesson/api-lesson-delete-lesson-post-plain';
import { apiLessonGetLessonPost } from '../fn/lesson/api-lesson-get-lesson-post';
import { ApiLessonGetLessonPost$Params } from '../fn/lesson/api-lesson-get-lesson-post';
import { apiLessonGetLessonPost$Plain } from '../fn/lesson/api-lesson-get-lesson-post-plain';
import { ApiLessonGetLessonPost$Plain$Params } from '../fn/lesson/api-lesson-get-lesson-post-plain';
import { apiLessonGetLessonQuestionCountPost } from '../fn/lesson/api-lesson-get-lesson-question-count-post';
import { ApiLessonGetLessonQuestionCountPost$Params } from '../fn/lesson/api-lesson-get-lesson-question-count-post';
import { apiLessonGetLessonQuestionCountPost$Plain } from '../fn/lesson/api-lesson-get-lesson-question-count-post-plain';
import { ApiLessonGetLessonQuestionCountPost$Plain$Params } from '../fn/lesson/api-lesson-get-lesson-question-count-post-plain';
import { apiLessonGetLessonsPost } from '../fn/lesson/api-lesson-get-lessons-post';
import { ApiLessonGetLessonsPost$Params } from '../fn/lesson/api-lesson-get-lessons-post';
import { apiLessonGetLessonsPost$Plain } from '../fn/lesson/api-lesson-get-lessons-post-plain';
import { ApiLessonGetLessonsPost$Plain$Params } from '../fn/lesson/api-lesson-get-lessons-post-plain';
import { apiLessonUpdateLessonPost } from '../fn/lesson/api-lesson-update-lesson-post';
import { ApiLessonUpdateLessonPost$Params } from '../fn/lesson/api-lesson-update-lesson-post';
import { apiLessonUpdateLessonPost$Plain } from '../fn/lesson/api-lesson-update-lesson-post-plain';
import { ApiLessonUpdateLessonPost$Plain$Params } from '../fn/lesson/api-lesson-update-lesson-post-plain';
import { CreateLessonCommandResult } from '../models/create-lesson-command-result';
import { CreateStudentLessonCompletionCommandResult } from '../models/create-student-lesson-completion-command-result';
import { DeleteLessonCommandResult } from '../models/delete-lesson-command-result';
import { GetLessonQueryResult } from '../models/get-lesson-query-result';
import { GetLessonQuestionCountQueryResult } from '../models/get-lesson-question-count-query-result';
import { GetLessonsQueryResult } from '../models/get-lessons-query-result';
import { UpdateLessonCommandResult } from '../models/update-lesson-command-result';

@Injectable({ providedIn: 'root' })
export class LessonService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiLessonGetLessonPost()` */
  static readonly ApiLessonGetLessonPostPath = '/api/Lesson/GetLesson';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonGetLessonPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonPost$Plain$Response(params?: ApiLessonGetLessonPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetLessonQueryResult>> {
    return apiLessonGetLessonPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonGetLessonPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonPost$Plain(params?: ApiLessonGetLessonPost$Plain$Params, context?: HttpContext): Observable<GetLessonQueryResult> {
    return this.apiLessonGetLessonPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetLessonQueryResult>): GetLessonQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonGetLessonPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonPost$Response(params?: ApiLessonGetLessonPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetLessonQueryResult>> {
    return apiLessonGetLessonPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonGetLessonPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonPost(params?: ApiLessonGetLessonPost$Params, context?: HttpContext): Observable<GetLessonQueryResult> {
    return this.apiLessonGetLessonPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetLessonQueryResult>): GetLessonQueryResult => r.body)
    );
  }

  /** Path part for operation `apiLessonGetLessonsPost()` */
  static readonly ApiLessonGetLessonsPostPath = '/api/Lesson/GetLessons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonGetLessonsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonsPost$Plain$Response(params?: ApiLessonGetLessonsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetLessonsQueryResult>> {
    return apiLessonGetLessonsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonGetLessonsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonsPost$Plain(params?: ApiLessonGetLessonsPost$Plain$Params, context?: HttpContext): Observable<GetLessonsQueryResult> {
    return this.apiLessonGetLessonsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetLessonsQueryResult>): GetLessonsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonGetLessonsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonsPost$Response(params?: ApiLessonGetLessonsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetLessonsQueryResult>> {
    return apiLessonGetLessonsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonGetLessonsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonsPost(params?: ApiLessonGetLessonsPost$Params, context?: HttpContext): Observable<GetLessonsQueryResult> {
    return this.apiLessonGetLessonsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetLessonsQueryResult>): GetLessonsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiLessonGetLessonQuestionCountPost()` */
  static readonly ApiLessonGetLessonQuestionCountPostPath = '/api/Lesson/GetLessonQuestionCount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonGetLessonQuestionCountPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonQuestionCountPost$Plain$Response(params?: ApiLessonGetLessonQuestionCountPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetLessonQuestionCountQueryResult>> {
    return apiLessonGetLessonQuestionCountPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonGetLessonQuestionCountPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonQuestionCountPost$Plain(params?: ApiLessonGetLessonQuestionCountPost$Plain$Params, context?: HttpContext): Observable<GetLessonQuestionCountQueryResult> {
    return this.apiLessonGetLessonQuestionCountPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetLessonQuestionCountQueryResult>): GetLessonQuestionCountQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonGetLessonQuestionCountPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonQuestionCountPost$Response(params?: ApiLessonGetLessonQuestionCountPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetLessonQuestionCountQueryResult>> {
    return apiLessonGetLessonQuestionCountPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonGetLessonQuestionCountPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonGetLessonQuestionCountPost(params?: ApiLessonGetLessonQuestionCountPost$Params, context?: HttpContext): Observable<GetLessonQuestionCountQueryResult> {
    return this.apiLessonGetLessonQuestionCountPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetLessonQuestionCountQueryResult>): GetLessonQuestionCountQueryResult => r.body)
    );
  }

  /** Path part for operation `apiLessonCreateLessonPost()` */
  static readonly ApiLessonCreateLessonPostPath = '/api/Lesson/CreateLesson';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonCreateLessonPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonCreateLessonPost$Plain$Response(params?: ApiLessonCreateLessonPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateLessonCommandResult>> {
    return apiLessonCreateLessonPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonCreateLessonPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonCreateLessonPost$Plain(params?: ApiLessonCreateLessonPost$Plain$Params, context?: HttpContext): Observable<CreateLessonCommandResult> {
    return this.apiLessonCreateLessonPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateLessonCommandResult>): CreateLessonCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonCreateLessonPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonCreateLessonPost$Response(params?: ApiLessonCreateLessonPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateLessonCommandResult>> {
    return apiLessonCreateLessonPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonCreateLessonPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonCreateLessonPost(params?: ApiLessonCreateLessonPost$Params, context?: HttpContext): Observable<CreateLessonCommandResult> {
    return this.apiLessonCreateLessonPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateLessonCommandResult>): CreateLessonCommandResult => r.body)
    );
  }

  /** Path part for operation `apiLessonCreateStudentLessonCompletionPost()` */
  static readonly ApiLessonCreateStudentLessonCompletionPostPath = '/api/Lesson/CreateStudentLessonCompletion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonCreateStudentLessonCompletionPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonCreateStudentLessonCompletionPost$Plain$Response(params?: ApiLessonCreateStudentLessonCompletionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateStudentLessonCompletionCommandResult>> {
    return apiLessonCreateStudentLessonCompletionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonCreateStudentLessonCompletionPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonCreateStudentLessonCompletionPost$Plain(params?: ApiLessonCreateStudentLessonCompletionPost$Plain$Params, context?: HttpContext): Observable<CreateStudentLessonCompletionCommandResult> {
    return this.apiLessonCreateStudentLessonCompletionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateStudentLessonCompletionCommandResult>): CreateStudentLessonCompletionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonCreateStudentLessonCompletionPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonCreateStudentLessonCompletionPost$Response(params?: ApiLessonCreateStudentLessonCompletionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateStudentLessonCompletionCommandResult>> {
    return apiLessonCreateStudentLessonCompletionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonCreateStudentLessonCompletionPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonCreateStudentLessonCompletionPost(params?: ApiLessonCreateStudentLessonCompletionPost$Params, context?: HttpContext): Observable<CreateStudentLessonCompletionCommandResult> {
    return this.apiLessonCreateStudentLessonCompletionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateStudentLessonCompletionCommandResult>): CreateStudentLessonCompletionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiLessonUpdateLessonPost()` */
  static readonly ApiLessonUpdateLessonPostPath = '/api/Lesson/UpdateLesson';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonUpdateLessonPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonUpdateLessonPost$Plain$Response(params?: ApiLessonUpdateLessonPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateLessonCommandResult>> {
    return apiLessonUpdateLessonPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonUpdateLessonPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonUpdateLessonPost$Plain(params?: ApiLessonUpdateLessonPost$Plain$Params, context?: HttpContext): Observable<UpdateLessonCommandResult> {
    return this.apiLessonUpdateLessonPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateLessonCommandResult>): UpdateLessonCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonUpdateLessonPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonUpdateLessonPost$Response(params?: ApiLessonUpdateLessonPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateLessonCommandResult>> {
    return apiLessonUpdateLessonPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonUpdateLessonPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLessonUpdateLessonPost(params?: ApiLessonUpdateLessonPost$Params, context?: HttpContext): Observable<UpdateLessonCommandResult> {
    return this.apiLessonUpdateLessonPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateLessonCommandResult>): UpdateLessonCommandResult => r.body)
    );
  }

  /** Path part for operation `apiLessonDeleteLessonPost()` */
  static readonly ApiLessonDeleteLessonPostPath = '/api/Lesson/DeleteLesson';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonDeleteLessonPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonDeleteLessonPost$Plain$Response(params?: ApiLessonDeleteLessonPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteLessonCommandResult>> {
    return apiLessonDeleteLessonPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonDeleteLessonPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonDeleteLessonPost$Plain(params?: ApiLessonDeleteLessonPost$Plain$Params, context?: HttpContext): Observable<DeleteLessonCommandResult> {
    return this.apiLessonDeleteLessonPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteLessonCommandResult>): DeleteLessonCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLessonDeleteLessonPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonDeleteLessonPost$Response(params?: ApiLessonDeleteLessonPost$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteLessonCommandResult>> {
    return apiLessonDeleteLessonPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLessonDeleteLessonPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLessonDeleteLessonPost(params?: ApiLessonDeleteLessonPost$Params, context?: HttpContext): Observable<DeleteLessonCommandResult> {
    return this.apiLessonDeleteLessonPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteLessonCommandResult>): DeleteLessonCommandResult => r.body)
    );
  }

}
