/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AnswerHomeworkDto } from '../../models/answer-homework-dto';
import { TExamQuestionType } from '../../models/t-exam-question-type';
import { UpdateHomeworkQuestionCommandResult } from '../../models/update-homework-question-command-result';
import { UpdateHomeworkQuestionHomeworkDto } from '../../models/update-homework-question-homework-dto';

export interface ApiHomeworkUpdateHomeworkQuestionPost$Plain$Params {
      body?: {
'Question.Id'?: string;
'Question.AudioFile'?: Blob;
'Question.AudioPlayTimes'?: number;
'Question.Type'?: TExamQuestionType;
'Question.No'?: number;
'Question.Title'?: string;
'Question.QuestionImage'?: Blob;
'Question.HomeworkId'?: number;
'Question.Answers'?: Array<AnswerHomeworkDto>;
'SubQuestions'?: Array<UpdateHomeworkQuestionHomeworkDto>;
}
}

export function apiHomeworkUpdateHomeworkQuestionPost$Plain(http: HttpClient, rootUrl: string, params?: ApiHomeworkUpdateHomeworkQuestionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateHomeworkQuestionCommandResult>> {
  const rb = new RequestBuilder(rootUrl, apiHomeworkUpdateHomeworkQuestionPost$Plain.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UpdateHomeworkQuestionCommandResult>;
    })
  );
}

apiHomeworkUpdateHomeworkQuestionPost$Plain.PATH = '/api/Homework/UpdateHomeworkQuestion';
