/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreateTalentStudentCommandResult } from '../../models/create-talent-student-command-result';
import { Gender } from '../../models/gender';

export interface ApiTalentedStudentsCreateTalentedStudentPost$Params {
      body?: {
'Name'?: string;
'Description'?: string;
'Gender'?: Gender;
'Sort'?: number;
'Image'?: Blob;
'StudentId'?: string;
}
}

export function apiTalentedStudentsCreateTalentedStudentPost(http: HttpClient, rootUrl: string, params?: ApiTalentedStudentsCreateTalentedStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTalentStudentCommandResult>> {
  const rb = new RequestBuilder(rootUrl, apiTalentedStudentsCreateTalentedStudentPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CreateTalentStudentCommandResult>;
    })
  );
}

apiTalentedStudentsCreateTalentedStudentPost.PATH = '/api/TalentedStudents/CreateTalentedStudent';
