/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiQuestionCreateQuestionPost } from '../fn/question/api-question-create-question-post';
import { ApiQuestionCreateQuestionPost$Params } from '../fn/question/api-question-create-question-post';
import { apiQuestionCreateQuestionPost$Plain } from '../fn/question/api-question-create-question-post-plain';
import { ApiQuestionCreateQuestionPost$Plain$Params } from '../fn/question/api-question-create-question-post-plain';
import { apiQuestionDeleteMultiQuestionPost } from '../fn/question/api-question-delete-multi-question-post';
import { ApiQuestionDeleteMultiQuestionPost$Params } from '../fn/question/api-question-delete-multi-question-post';
import { apiQuestionDeleteMultiQuestionPost$Plain } from '../fn/question/api-question-delete-multi-question-post-plain';
import { ApiQuestionDeleteMultiQuestionPost$Plain$Params } from '../fn/question/api-question-delete-multi-question-post-plain';
import { apiQuestionDeleteQuestionFilePost } from '../fn/question/api-question-delete-question-file-post';
import { ApiQuestionDeleteQuestionFilePost$Params } from '../fn/question/api-question-delete-question-file-post';
import { apiQuestionDeleteQuestionFilePost$Plain } from '../fn/question/api-question-delete-question-file-post-plain';
import { ApiQuestionDeleteQuestionFilePost$Plain$Params } from '../fn/question/api-question-delete-question-file-post-plain';
import { apiQuestionDeleteQuestionPost } from '../fn/question/api-question-delete-question-post';
import { ApiQuestionDeleteQuestionPost$Params } from '../fn/question/api-question-delete-question-post';
import { apiQuestionDeleteQuestionPost$Plain } from '../fn/question/api-question-delete-question-post-plain';
import { ApiQuestionDeleteQuestionPost$Plain$Params } from '../fn/question/api-question-delete-question-post-plain';
import { apiQuestionDownloadQuestionsSheetPost } from '../fn/question/api-question-download-questions-sheet-post';
import { ApiQuestionDownloadQuestionsSheetPost$Params } from '../fn/question/api-question-download-questions-sheet-post';
import { apiQuestionDownloadQuestionsSheetPost$Plain } from '../fn/question/api-question-download-questions-sheet-post-plain';
import { ApiQuestionDownloadQuestionsSheetPost$Plain$Params } from '../fn/question/api-question-download-questions-sheet-post-plain';
import { apiQuestionGetQuestionPost } from '../fn/question/api-question-get-question-post';
import { ApiQuestionGetQuestionPost$Params } from '../fn/question/api-question-get-question-post';
import { apiQuestionGetQuestionPost$Plain } from '../fn/question/api-question-get-question-post-plain';
import { ApiQuestionGetQuestionPost$Plain$Params } from '../fn/question/api-question-get-question-post-plain';
import { apiQuestionGetQuestionsPost } from '../fn/question/api-question-get-questions-post';
import { ApiQuestionGetQuestionsPost$Params } from '../fn/question/api-question-get-questions-post';
import { apiQuestionGetQuestionsPost$Plain } from '../fn/question/api-question-get-questions-post-plain';
import { ApiQuestionGetQuestionsPost$Plain$Params } from '../fn/question/api-question-get-questions-post-plain';
import { apiQuestionUpdateQuestionPost } from '../fn/question/api-question-update-question-post';
import { ApiQuestionUpdateQuestionPost$Params } from '../fn/question/api-question-update-question-post';
import { apiQuestionUpdateQuestionPost$Plain } from '../fn/question/api-question-update-question-post-plain';
import { ApiQuestionUpdateQuestionPost$Plain$Params } from '../fn/question/api-question-update-question-post-plain';
import { CreateQuestionCommandResult } from '../models/create-question-command-result';
import { DeleteMultiQuestionCommandResult } from '../models/delete-multi-question-command-result';
import { DeleteQuestionCommandResult } from '../models/delete-question-command-result';
import { DeleteQuestionFileCommandResult } from '../models/delete-question-file-command-result';
import { GetQuestionQueryResult } from '../models/get-question-query-result';
import { GetQuestionsQueryResult } from '../models/get-questions-query-result';
import { UpdateQuestionCommandResult } from '../models/update-question-command-result';

@Injectable({ providedIn: 'root' })
export class QuestionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiQuestionGetQuestionPost()` */
  static readonly ApiQuestionGetQuestionPostPath = '/api/Question/GetQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionGetQuestionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionGetQuestionPost$Plain$Response(params?: ApiQuestionGetQuestionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetQuestionQueryResult>> {
    return apiQuestionGetQuestionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionGetQuestionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionGetQuestionPost$Plain(params?: ApiQuestionGetQuestionPost$Plain$Params, context?: HttpContext): Observable<GetQuestionQueryResult> {
    return this.apiQuestionGetQuestionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetQuestionQueryResult>): GetQuestionQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionGetQuestionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionGetQuestionPost$Response(params?: ApiQuestionGetQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetQuestionQueryResult>> {
    return apiQuestionGetQuestionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionGetQuestionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionGetQuestionPost(params?: ApiQuestionGetQuestionPost$Params, context?: HttpContext): Observable<GetQuestionQueryResult> {
    return this.apiQuestionGetQuestionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetQuestionQueryResult>): GetQuestionQueryResult => r.body)
    );
  }

  /** Path part for operation `apiQuestionGetQuestionsPost()` */
  static readonly ApiQuestionGetQuestionsPostPath = '/api/Question/GetQuestions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionGetQuestionsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionGetQuestionsPost$Plain$Response(params?: ApiQuestionGetQuestionsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetQuestionsQueryResult>> {
    return apiQuestionGetQuestionsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionGetQuestionsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionGetQuestionsPost$Plain(params?: ApiQuestionGetQuestionsPost$Plain$Params, context?: HttpContext): Observable<GetQuestionsQueryResult> {
    return this.apiQuestionGetQuestionsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetQuestionsQueryResult>): GetQuestionsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionGetQuestionsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionGetQuestionsPost$Response(params?: ApiQuestionGetQuestionsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetQuestionsQueryResult>> {
    return apiQuestionGetQuestionsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionGetQuestionsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionGetQuestionsPost(params?: ApiQuestionGetQuestionsPost$Params, context?: HttpContext): Observable<GetQuestionsQueryResult> {
    return this.apiQuestionGetQuestionsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetQuestionsQueryResult>): GetQuestionsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiQuestionCreateQuestionPost()` */
  static readonly ApiQuestionCreateQuestionPostPath = '/api/Question/CreateQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionCreateQuestionPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionCreateQuestionPost$Plain$Response(params?: ApiQuestionCreateQuestionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateQuestionCommandResult>> {
    return apiQuestionCreateQuestionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionCreateQuestionPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionCreateQuestionPost$Plain(params?: ApiQuestionCreateQuestionPost$Plain$Params, context?: HttpContext): Observable<CreateQuestionCommandResult> {
    return this.apiQuestionCreateQuestionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateQuestionCommandResult>): CreateQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionCreateQuestionPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionCreateQuestionPost$Response(params?: ApiQuestionCreateQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateQuestionCommandResult>> {
    return apiQuestionCreateQuestionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionCreateQuestionPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionCreateQuestionPost(params?: ApiQuestionCreateQuestionPost$Params, context?: HttpContext): Observable<CreateQuestionCommandResult> {
    return this.apiQuestionCreateQuestionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateQuestionCommandResult>): CreateQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQuestionUpdateQuestionPost()` */
  static readonly ApiQuestionUpdateQuestionPostPath = '/api/Question/UpdateQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionUpdateQuestionPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionUpdateQuestionPost$Plain$Response(params?: ApiQuestionUpdateQuestionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateQuestionCommandResult>> {
    return apiQuestionUpdateQuestionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionUpdateQuestionPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionUpdateQuestionPost$Plain(params?: ApiQuestionUpdateQuestionPost$Plain$Params, context?: HttpContext): Observable<UpdateQuestionCommandResult> {
    return this.apiQuestionUpdateQuestionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateQuestionCommandResult>): UpdateQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionUpdateQuestionPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionUpdateQuestionPost$Response(params?: ApiQuestionUpdateQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateQuestionCommandResult>> {
    return apiQuestionUpdateQuestionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionUpdateQuestionPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQuestionUpdateQuestionPost(params?: ApiQuestionUpdateQuestionPost$Params, context?: HttpContext): Observable<UpdateQuestionCommandResult> {
    return this.apiQuestionUpdateQuestionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateQuestionCommandResult>): UpdateQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQuestionDeleteQuestionPost()` */
  static readonly ApiQuestionDeleteQuestionPostPath = '/api/Question/DeleteQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionDeleteQuestionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteQuestionPost$Plain$Response(params?: ApiQuestionDeleteQuestionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteQuestionCommandResult>> {
    return apiQuestionDeleteQuestionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionDeleteQuestionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteQuestionPost$Plain(params?: ApiQuestionDeleteQuestionPost$Plain$Params, context?: HttpContext): Observable<DeleteQuestionCommandResult> {
    return this.apiQuestionDeleteQuestionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteQuestionCommandResult>): DeleteQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionDeleteQuestionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteQuestionPost$Response(params?: ApiQuestionDeleteQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteQuestionCommandResult>> {
    return apiQuestionDeleteQuestionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionDeleteQuestionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteQuestionPost(params?: ApiQuestionDeleteQuestionPost$Params, context?: HttpContext): Observable<DeleteQuestionCommandResult> {
    return this.apiQuestionDeleteQuestionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteQuestionCommandResult>): DeleteQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQuestionDeleteMultiQuestionPost()` */
  static readonly ApiQuestionDeleteMultiQuestionPostPath = '/api/Question/DeleteMultiQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionDeleteMultiQuestionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteMultiQuestionPost$Plain$Response(params?: ApiQuestionDeleteMultiQuestionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteMultiQuestionCommandResult>> {
    return apiQuestionDeleteMultiQuestionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionDeleteMultiQuestionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteMultiQuestionPost$Plain(params?: ApiQuestionDeleteMultiQuestionPost$Plain$Params, context?: HttpContext): Observable<DeleteMultiQuestionCommandResult> {
    return this.apiQuestionDeleteMultiQuestionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteMultiQuestionCommandResult>): DeleteMultiQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionDeleteMultiQuestionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteMultiQuestionPost$Response(params?: ApiQuestionDeleteMultiQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteMultiQuestionCommandResult>> {
    return apiQuestionDeleteMultiQuestionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionDeleteMultiQuestionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteMultiQuestionPost(params?: ApiQuestionDeleteMultiQuestionPost$Params, context?: HttpContext): Observable<DeleteMultiQuestionCommandResult> {
    return this.apiQuestionDeleteMultiQuestionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteMultiQuestionCommandResult>): DeleteMultiQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQuestionDeleteQuestionFilePost()` */
  static readonly ApiQuestionDeleteQuestionFilePostPath = '/api/Question/DeleteQuestionFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionDeleteQuestionFilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteQuestionFilePost$Plain$Response(params?: ApiQuestionDeleteQuestionFilePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteQuestionFileCommandResult>> {
    return apiQuestionDeleteQuestionFilePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionDeleteQuestionFilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteQuestionFilePost$Plain(params?: ApiQuestionDeleteQuestionFilePost$Plain$Params, context?: HttpContext): Observable<DeleteQuestionFileCommandResult> {
    return this.apiQuestionDeleteQuestionFilePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteQuestionFileCommandResult>): DeleteQuestionFileCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionDeleteQuestionFilePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteQuestionFilePost$Response(params?: ApiQuestionDeleteQuestionFilePost$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteQuestionFileCommandResult>> {
    return apiQuestionDeleteQuestionFilePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionDeleteQuestionFilePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDeleteQuestionFilePost(params?: ApiQuestionDeleteQuestionFilePost$Params, context?: HttpContext): Observable<DeleteQuestionFileCommandResult> {
    return this.apiQuestionDeleteQuestionFilePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteQuestionFileCommandResult>): DeleteQuestionFileCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQuestionDownloadQuestionsSheetPost()` */
  static readonly ApiQuestionDownloadQuestionsSheetPostPath = '/api/Question/DownloadQuestionsSheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionDownloadQuestionsSheetPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDownloadQuestionsSheetPost$Plain$Response(params?: ApiQuestionDownloadQuestionsSheetPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return apiQuestionDownloadQuestionsSheetPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionDownloadQuestionsSheetPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDownloadQuestionsSheetPost$Plain(params?: ApiQuestionDownloadQuestionsSheetPost$Plain$Params, context?: HttpContext): Observable<Blob> {
    return this.apiQuestionDownloadQuestionsSheetPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionDownloadQuestionsSheetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDownloadQuestionsSheetPost$Response(params?: ApiQuestionDownloadQuestionsSheetPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return apiQuestionDownloadQuestionsSheetPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionDownloadQuestionsSheetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionDownloadQuestionsSheetPost(params?: ApiQuestionDownloadQuestionsSheetPost$Params, context?: HttpContext): Observable<Blob> {
    return this.apiQuestionDownloadQuestionsSheetPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
