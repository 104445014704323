<div class="position-relative grid-layout">
    <nav class="py-2 shadow sticky-top bg-white">
        <div class="container">
            <div class="d-flex flex-wrap justify-content-between align-items-center">

                <span>
                    <img class="d-none d-lg-block" src="../../../assets/images/logo-dark.svg" style="height: 55px;"
                        alt="">
                    <img class=" d-lg-none" src="../../../assets/images/logo-dark.svg" style="height: 45px;" alt="">
                </span>

                <div class="d-none d-lg-flex flex-wrap gap-3 align-items-center">
                    <button *ngIf="!acc" routerLink="/account/login" class="btn btn-sm py-2 btn-primary">
                        <span class="isax-login fs-6 align-middle"></span>
                        تسجيل دخول
                    </button>
                    <button *ngIf="!acc" routerLink="/account/register" class="btn btn-sm py-2 btn-warning">
                        <span class="isax-user-cirlce-add fs-6 align-middle"></span>
                        إنشاء حساب جديد
                    </button>
                    <button *ngIf="acc" (click)="redirectUser()" class="btn btn-link btn-sm d-none d-lg-inline-block">
                        <span class="isax-user-square lead align-middle "></span>
                        <span>

                            إلى لوحتي التعليمية
                        </span>
                    </button>
                    <mg-account-dropdown *ngIf="acc"></mg-account-dropdown>
                    <a href="https://wa.me/966538720990" target="_blank" referrerpolicy="no-referrer" class="">
                        <span class="isax-headphone align-middle"></span>
                        <small>
                            الدعم الفني
                        </small>
                    </a>
                </div>

                <div ngbDropdown [container]="'body'" [display]="'dynamic'" class="d-inline-block d-lg-none">
                    <div ngbDropdownToggle class="d-flex gap-2 align-items-center">
                        <span class="notif_circle me-1">
                            <span class="isax-profile text-primary fs-5">

                            </span>
                        </span>
                        <span *ngIf="acc" class="text-truncate d-lg-inline-block d-none" style="max-width: 100px;">
                            {{acc?.fullName || acc?.userName}}
                        </span>
                    </div>
                    <div ngbDropdownMenu>
                        <button *ngIf="!acc" routerLink="/account/login" class="dropdown-item text-body">
                            <span class="isax-login align-middle"></span>
                            تسجيل دخول
                        </button>
                        <button *ngIf="!acc" routerLink="/account/register" class="dropdown-item text-body">
                            <span class="isax-user-cirlce-add align-middle"></span>
                            إنشاء حساب جديد
                        </button>
                        <button *ngIf="acc" (click)="logout()" class="dropdown-item text-danger">
                            <span class="isax-logout align-middle"></span>
                            تسجيل الخروج
                        </button>
                        <a href="https://wa.me/966538720990" target="_blank" referrerpolicy="no-referrer"
                            class="dropdown-item">
                            <span class="bx bxl-whatsapp align-middle"></span>
                            الدعم الفني
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </nav>

    <div>
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
</div>