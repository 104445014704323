import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Page404Component } from './extrapages/page404/page404.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { VerticalComponent } from './layouts/vertical/vertical.component';

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    loadChildren: () =>
      import('./extrapages/extrapages.module').then((m) => m.ExtrapagesModule),
  },
  {
    path: '',
    component: VerticalComponent,
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    // canActivate: [AuthGuard],
    // data: {
    //   roles: []
    // }
  },

  {
    path: 'account',
    loadChildren: () =>
      import('./account/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'loader',
    loadComponent: () =>
      import('./standalone-components/spinner/spinner.component').then(
        (m) => m.SpinnerComponent,
      ),
  },
  {
    path: '**',
    component: Page404Component,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'ignore',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
