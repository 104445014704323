/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Gender } from '../../models/gender';
import { UpdateTalentedStudentCommandResult } from '../../models/update-talented-student-command-result';

export interface ApiTalentedStudentsUpdateTalentedStudentPost$Params {
      body?: {
'Id': string;
'Name'?: string;
'Description'?: string;
'Sort'?: number;
'Gender'?: Gender;
'Image'?: Blob;
'StudentId'?: string;
}
}

export function apiTalentedStudentsUpdateTalentedStudentPost(http: HttpClient, rootUrl: string, params?: ApiTalentedStudentsUpdateTalentedStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTalentedStudentCommandResult>> {
  const rb = new RequestBuilder(rootUrl, apiTalentedStudentsUpdateTalentedStudentPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UpdateTalentedStudentCommandResult>;
    })
  );
}

apiTalentedStudentsUpdateTalentedStudentPost.PATH = '/api/TalentedStudents/UpdateTalentedStudent';
