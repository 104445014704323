/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiHomeworkCreateHomeworkPost } from '../fn/homework/api-homework-create-homework-post';
import { ApiHomeworkCreateHomeworkPost$Params } from '../fn/homework/api-homework-create-homework-post';
import { apiHomeworkCreateHomeworkPost$Plain } from '../fn/homework/api-homework-create-homework-post-plain';
import { ApiHomeworkCreateHomeworkPost$Plain$Params } from '../fn/homework/api-homework-create-homework-post-plain';
import { apiHomeworkCreateHomeworkQuestionPost } from '../fn/homework/api-homework-create-homework-question-post';
import { ApiHomeworkCreateHomeworkQuestionPost$Params } from '../fn/homework/api-homework-create-homework-question-post';
import { apiHomeworkCreateHomeworkQuestionPost$Plain } from '../fn/homework/api-homework-create-homework-question-post-plain';
import { ApiHomeworkCreateHomeworkQuestionPost$Plain$Params } from '../fn/homework/api-homework-create-homework-question-post-plain';
import { apiHomeworkDeleteHomeworkDelete } from '../fn/homework/api-homework-delete-homework-delete';
import { ApiHomeworkDeleteHomeworkDelete$Params } from '../fn/homework/api-homework-delete-homework-delete';
import { apiHomeworkDeleteHomeworkDelete$Plain } from '../fn/homework/api-homework-delete-homework-delete-plain';
import { ApiHomeworkDeleteHomeworkDelete$Plain$Params } from '../fn/homework/api-homework-delete-homework-delete-plain';
import { apiHomeworkDeleteHomeworkSolutionDelete } from '../fn/homework/api-homework-delete-homework-solution-delete';
import { ApiHomeworkDeleteHomeworkSolutionDelete$Params } from '../fn/homework/api-homework-delete-homework-solution-delete';
import { apiHomeworkDeleteHomeworkSolutionDelete$Plain } from '../fn/homework/api-homework-delete-homework-solution-delete-plain';
import { ApiHomeworkDeleteHomeworkSolutionDelete$Plain$Params } from '../fn/homework/api-homework-delete-homework-solution-delete-plain';
import { apiHomeworkGetHomeworkForStudentPost } from '../fn/homework/api-homework-get-homework-for-student-post';
import { ApiHomeworkGetHomeworkForStudentPost$Params } from '../fn/homework/api-homework-get-homework-for-student-post';
import { apiHomeworkGetHomeworkForStudentPost$Plain } from '../fn/homework/api-homework-get-homework-for-student-post-plain';
import { ApiHomeworkGetHomeworkForStudentPost$Plain$Params } from '../fn/homework/api-homework-get-homework-for-student-post-plain';
import { apiHomeworkGetHomeworkForTeacherPost } from '../fn/homework/api-homework-get-homework-for-teacher-post';
import { ApiHomeworkGetHomeworkForTeacherPost$Params } from '../fn/homework/api-homework-get-homework-for-teacher-post';
import { apiHomeworkGetHomeworkForTeacherPost$Plain } from '../fn/homework/api-homework-get-homework-for-teacher-post-plain';
import { ApiHomeworkGetHomeworkForTeacherPost$Plain$Params } from '../fn/homework/api-homework-get-homework-for-teacher-post-plain';
import { apiHomeworkGetHomeworksForStudentPost } from '../fn/homework/api-homework-get-homeworks-for-student-post';
import { ApiHomeworkGetHomeworksForStudentPost$Params } from '../fn/homework/api-homework-get-homeworks-for-student-post';
import { apiHomeworkGetHomeworksForStudentPost$Plain } from '../fn/homework/api-homework-get-homeworks-for-student-post-plain';
import { ApiHomeworkGetHomeworksForStudentPost$Plain$Params } from '../fn/homework/api-homework-get-homeworks-for-student-post-plain';
import { apiHomeworkGetHomeworksForTeacherPost } from '../fn/homework/api-homework-get-homeworks-for-teacher-post';
import { ApiHomeworkGetHomeworksForTeacherPost$Params } from '../fn/homework/api-homework-get-homeworks-for-teacher-post';
import { apiHomeworkGetHomeworksForTeacherPost$Plain } from '../fn/homework/api-homework-get-homeworks-for-teacher-post-plain';
import { ApiHomeworkGetHomeworksForTeacherPost$Plain$Params } from '../fn/homework/api-homework-get-homeworks-for-teacher-post-plain';
import { apiHomeworkGetHomeworkStudentSolvesPost } from '../fn/homework/api-homework-get-homework-student-solves-post';
import { ApiHomeworkGetHomeworkStudentSolvesPost$Params } from '../fn/homework/api-homework-get-homework-student-solves-post';
import { apiHomeworkGetHomeworkStudentSolvesPost$Plain } from '../fn/homework/api-homework-get-homework-student-solves-post-plain';
import { ApiHomeworkGetHomeworkStudentSolvesPost$Plain$Params } from '../fn/homework/api-homework-get-homework-student-solves-post-plain';
import { apiHomeworkGetHomeworkStudentsPost } from '../fn/homework/api-homework-get-homework-students-post';
import { ApiHomeworkGetHomeworkStudentsPost$Params } from '../fn/homework/api-homework-get-homework-students-post';
import { apiHomeworkGetHomeworkStudentsPost$Plain } from '../fn/homework/api-homework-get-homework-students-post-plain';
import { ApiHomeworkGetHomeworkStudentsPost$Plain$Params } from '../fn/homework/api-homework-get-homework-students-post-plain';
import { apiHomeworkGetHwStudentSolutionPost } from '../fn/homework/api-homework-get-hw-student-solution-post';
import { ApiHomeworkGetHwStudentSolutionPost$Params } from '../fn/homework/api-homework-get-hw-student-solution-post';
import { apiHomeworkGetHwStudentSolutionPost$Plain } from '../fn/homework/api-homework-get-hw-student-solution-post-plain';
import { ApiHomeworkGetHwStudentSolutionPost$Plain$Params } from '../fn/homework/api-homework-get-hw-student-solution-post-plain';
import { apiHomeworkSaveHomeworkAnswerPost } from '../fn/homework/api-homework-save-homework-answer-post';
import { ApiHomeworkSaveHomeworkAnswerPost$Params } from '../fn/homework/api-homework-save-homework-answer-post';
import { apiHomeworkSaveHomeworkAnswerPost$Plain } from '../fn/homework/api-homework-save-homework-answer-post-plain';
import { ApiHomeworkSaveHomeworkAnswerPost$Plain$Params } from '../fn/homework/api-homework-save-homework-answer-post-plain';
import { apiHomeworkStudentStartSolvingPost } from '../fn/homework/api-homework-student-start-solving-post';
import { ApiHomeworkStudentStartSolvingPost$Params } from '../fn/homework/api-homework-student-start-solving-post';
import { apiHomeworkStudentStartSolvingPost$Plain } from '../fn/homework/api-homework-student-start-solving-post-plain';
import { ApiHomeworkStudentStartSolvingPost$Plain$Params } from '../fn/homework/api-homework-student-start-solving-post-plain';
import { apiHomeworkSubmitHomeworkStudentPost } from '../fn/homework/api-homework-submit-homework-student-post';
import { ApiHomeworkSubmitHomeworkStudentPost$Params } from '../fn/homework/api-homework-submit-homework-student-post';
import { apiHomeworkSubmitHomeworkStudentPost$Plain } from '../fn/homework/api-homework-submit-homework-student-post-plain';
import { ApiHomeworkSubmitHomeworkStudentPost$Plain$Params } from '../fn/homework/api-homework-submit-homework-student-post-plain';
import { apiHomeworkUpdateHomeworkPost } from '../fn/homework/api-homework-update-homework-post';
import { ApiHomeworkUpdateHomeworkPost$Params } from '../fn/homework/api-homework-update-homework-post';
import { apiHomeworkUpdateHomeworkPost$Plain } from '../fn/homework/api-homework-update-homework-post-plain';
import { ApiHomeworkUpdateHomeworkPost$Plain$Params } from '../fn/homework/api-homework-update-homework-post-plain';
import { apiHomeworkUpdateHomeworkQuestionPost } from '../fn/homework/api-homework-update-homework-question-post';
import { ApiHomeworkUpdateHomeworkQuestionPost$Params } from '../fn/homework/api-homework-update-homework-question-post';
import { apiHomeworkUpdateHomeworkQuestionPost$Plain } from '../fn/homework/api-homework-update-homework-question-post-plain';
import { ApiHomeworkUpdateHomeworkQuestionPost$Plain$Params } from '../fn/homework/api-homework-update-homework-question-post-plain';
import { CreateHomeworkCommandResult } from '../models/create-homework-command-result';
import { CreateHomeworkQuestionCommandResult } from '../models/create-homework-question-command-result';
import { DeleteHomeworkCommandResult } from '../models/delete-homework-command-result';
import { DeleteHomeworkSolutionCommandResult } from '../models/delete-homework-solution-command-result';
import { GetHomeworkForStudentQueryResult } from '../models/get-homework-for-student-query-result';
import { GetHomeworkQueryResult } from '../models/get-homework-query-result';
import { GetHomeworksForStudentQueryResult } from '../models/get-homeworks-for-student-query-result';
import { GetHomeworksForTeacherQueryResult } from '../models/get-homeworks-for-teacher-query-result';
import { GetHomeworkStudentSolvesQueryResult } from '../models/get-homework-student-solves-query-result';
import { GetHomeworkStudentsQueryResult } from '../models/get-homework-students-query-result';
import { GetHwStudentSolutionQueryResult } from '../models/get-hw-student-solution-query-result';
import { SaveHomeworkAnswerCommandResult } from '../models/save-homework-answer-command-result';
import { StudentStartSolvingCommandResult } from '../models/student-start-solving-command-result';
import { SubmitHomeworkStudentCommandResult } from '../models/submit-homework-student-command-result';
import { UpdateHomeworkCommandResult } from '../models/update-homework-command-result';
import { UpdateHomeworkQuestionCommandResult } from '../models/update-homework-question-command-result';

@Injectable({ providedIn: 'root' })
export class HomeworkService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiHomeworkCreateHomeworkPost()` */
  static readonly ApiHomeworkCreateHomeworkPostPath = '/api/Homework/CreateHomework';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkCreateHomeworkPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkCreateHomeworkPost$Plain$Response(params?: ApiHomeworkCreateHomeworkPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateHomeworkCommandResult>> {
    return apiHomeworkCreateHomeworkPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkCreateHomeworkPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkCreateHomeworkPost$Plain(params?: ApiHomeworkCreateHomeworkPost$Plain$Params, context?: HttpContext): Observable<CreateHomeworkCommandResult> {
    return this.apiHomeworkCreateHomeworkPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateHomeworkCommandResult>): CreateHomeworkCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkCreateHomeworkPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkCreateHomeworkPost$Response(params?: ApiHomeworkCreateHomeworkPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateHomeworkCommandResult>> {
    return apiHomeworkCreateHomeworkPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkCreateHomeworkPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkCreateHomeworkPost(params?: ApiHomeworkCreateHomeworkPost$Params, context?: HttpContext): Observable<CreateHomeworkCommandResult> {
    return this.apiHomeworkCreateHomeworkPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateHomeworkCommandResult>): CreateHomeworkCommandResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkUpdateHomeworkPost()` */
  static readonly ApiHomeworkUpdateHomeworkPostPath = '/api/Homework/UpdateHomework';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkUpdateHomeworkPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkUpdateHomeworkPost$Plain$Response(params?: ApiHomeworkUpdateHomeworkPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateHomeworkCommandResult>> {
    return apiHomeworkUpdateHomeworkPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkUpdateHomeworkPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkUpdateHomeworkPost$Plain(params?: ApiHomeworkUpdateHomeworkPost$Plain$Params, context?: HttpContext): Observable<UpdateHomeworkCommandResult> {
    return this.apiHomeworkUpdateHomeworkPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateHomeworkCommandResult>): UpdateHomeworkCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkUpdateHomeworkPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkUpdateHomeworkPost$Response(params?: ApiHomeworkUpdateHomeworkPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateHomeworkCommandResult>> {
    return apiHomeworkUpdateHomeworkPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkUpdateHomeworkPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkUpdateHomeworkPost(params?: ApiHomeworkUpdateHomeworkPost$Params, context?: HttpContext): Observable<UpdateHomeworkCommandResult> {
    return this.apiHomeworkUpdateHomeworkPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateHomeworkCommandResult>): UpdateHomeworkCommandResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkDeleteHomeworkDelete()` */
  static readonly ApiHomeworkDeleteHomeworkDeletePath = '/api/Homework/DeleteHomework';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkDeleteHomeworkDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkDeleteHomeworkDelete$Plain$Response(params?: ApiHomeworkDeleteHomeworkDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteHomeworkCommandResult>> {
    return apiHomeworkDeleteHomeworkDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkDeleteHomeworkDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkDeleteHomeworkDelete$Plain(params?: ApiHomeworkDeleteHomeworkDelete$Plain$Params, context?: HttpContext): Observable<DeleteHomeworkCommandResult> {
    return this.apiHomeworkDeleteHomeworkDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteHomeworkCommandResult>): DeleteHomeworkCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkDeleteHomeworkDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkDeleteHomeworkDelete$Response(params?: ApiHomeworkDeleteHomeworkDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteHomeworkCommandResult>> {
    return apiHomeworkDeleteHomeworkDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkDeleteHomeworkDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkDeleteHomeworkDelete(params?: ApiHomeworkDeleteHomeworkDelete$Params, context?: HttpContext): Observable<DeleteHomeworkCommandResult> {
    return this.apiHomeworkDeleteHomeworkDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteHomeworkCommandResult>): DeleteHomeworkCommandResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkDeleteHomeworkSolutionDelete()` */
  static readonly ApiHomeworkDeleteHomeworkSolutionDeletePath = '/api/Homework/DeleteHomeworkSolution';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkDeleteHomeworkSolutionDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkDeleteHomeworkSolutionDelete$Plain$Response(params?: ApiHomeworkDeleteHomeworkSolutionDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteHomeworkSolutionCommandResult>> {
    return apiHomeworkDeleteHomeworkSolutionDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkDeleteHomeworkSolutionDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkDeleteHomeworkSolutionDelete$Plain(params?: ApiHomeworkDeleteHomeworkSolutionDelete$Plain$Params, context?: HttpContext): Observable<DeleteHomeworkSolutionCommandResult> {
    return this.apiHomeworkDeleteHomeworkSolutionDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteHomeworkSolutionCommandResult>): DeleteHomeworkSolutionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkDeleteHomeworkSolutionDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkDeleteHomeworkSolutionDelete$Response(params?: ApiHomeworkDeleteHomeworkSolutionDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteHomeworkSolutionCommandResult>> {
    return apiHomeworkDeleteHomeworkSolutionDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkDeleteHomeworkSolutionDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkDeleteHomeworkSolutionDelete(params?: ApiHomeworkDeleteHomeworkSolutionDelete$Params, context?: HttpContext): Observable<DeleteHomeworkSolutionCommandResult> {
    return this.apiHomeworkDeleteHomeworkSolutionDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteHomeworkSolutionCommandResult>): DeleteHomeworkSolutionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkCreateHomeworkQuestionPost()` */
  static readonly ApiHomeworkCreateHomeworkQuestionPostPath = '/api/Homework/CreateHomeworkQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkCreateHomeworkQuestionPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkCreateHomeworkQuestionPost$Plain$Response(params?: ApiHomeworkCreateHomeworkQuestionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateHomeworkQuestionCommandResult>> {
    return apiHomeworkCreateHomeworkQuestionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkCreateHomeworkQuestionPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkCreateHomeworkQuestionPost$Plain(params?: ApiHomeworkCreateHomeworkQuestionPost$Plain$Params, context?: HttpContext): Observable<CreateHomeworkQuestionCommandResult> {
    return this.apiHomeworkCreateHomeworkQuestionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateHomeworkQuestionCommandResult>): CreateHomeworkQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkCreateHomeworkQuestionPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkCreateHomeworkQuestionPost$Response(params?: ApiHomeworkCreateHomeworkQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateHomeworkQuestionCommandResult>> {
    return apiHomeworkCreateHomeworkQuestionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkCreateHomeworkQuestionPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkCreateHomeworkQuestionPost(params?: ApiHomeworkCreateHomeworkQuestionPost$Params, context?: HttpContext): Observable<CreateHomeworkQuestionCommandResult> {
    return this.apiHomeworkCreateHomeworkQuestionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateHomeworkQuestionCommandResult>): CreateHomeworkQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkUpdateHomeworkQuestionPost()` */
  static readonly ApiHomeworkUpdateHomeworkQuestionPostPath = '/api/Homework/UpdateHomeworkQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkUpdateHomeworkQuestionPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkUpdateHomeworkQuestionPost$Plain$Response(params?: ApiHomeworkUpdateHomeworkQuestionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateHomeworkQuestionCommandResult>> {
    return apiHomeworkUpdateHomeworkQuestionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkUpdateHomeworkQuestionPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkUpdateHomeworkQuestionPost$Plain(params?: ApiHomeworkUpdateHomeworkQuestionPost$Plain$Params, context?: HttpContext): Observable<UpdateHomeworkQuestionCommandResult> {
    return this.apiHomeworkUpdateHomeworkQuestionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateHomeworkQuestionCommandResult>): UpdateHomeworkQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkUpdateHomeworkQuestionPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkUpdateHomeworkQuestionPost$Response(params?: ApiHomeworkUpdateHomeworkQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateHomeworkQuestionCommandResult>> {
    return apiHomeworkUpdateHomeworkQuestionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkUpdateHomeworkQuestionPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkUpdateHomeworkQuestionPost(params?: ApiHomeworkUpdateHomeworkQuestionPost$Params, context?: HttpContext): Observable<UpdateHomeworkQuestionCommandResult> {
    return this.apiHomeworkUpdateHomeworkQuestionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateHomeworkQuestionCommandResult>): UpdateHomeworkQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkGetHomeworkForTeacherPost()` */
  static readonly ApiHomeworkGetHomeworkForTeacherPostPath = '/api/Homework/GetHomeworkForTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworkForTeacherPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkForTeacherPost$Plain$Response(params?: ApiHomeworkGetHomeworkForTeacherPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworkQueryResult>> {
    return apiHomeworkGetHomeworkForTeacherPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworkForTeacherPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkForTeacherPost$Plain(params?: ApiHomeworkGetHomeworkForTeacherPost$Plain$Params, context?: HttpContext): Observable<GetHomeworkQueryResult> {
    return this.apiHomeworkGetHomeworkForTeacherPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworkQueryResult>): GetHomeworkQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworkForTeacherPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkForTeacherPost$Response(params?: ApiHomeworkGetHomeworkForTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworkQueryResult>> {
    return apiHomeworkGetHomeworkForTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworkForTeacherPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkForTeacherPost(params?: ApiHomeworkGetHomeworkForTeacherPost$Params, context?: HttpContext): Observable<GetHomeworkQueryResult> {
    return this.apiHomeworkGetHomeworkForTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworkQueryResult>): GetHomeworkQueryResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkGetHomeworksForTeacherPost()` */
  static readonly ApiHomeworkGetHomeworksForTeacherPostPath = '/api/Homework/GetHomeworksForTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworksForTeacherPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworksForTeacherPost$Plain$Response(params?: ApiHomeworkGetHomeworksForTeacherPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworksForTeacherQueryResult>> {
    return apiHomeworkGetHomeworksForTeacherPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworksForTeacherPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworksForTeacherPost$Plain(params?: ApiHomeworkGetHomeworksForTeacherPost$Plain$Params, context?: HttpContext): Observable<GetHomeworksForTeacherQueryResult> {
    return this.apiHomeworkGetHomeworksForTeacherPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworksForTeacherQueryResult>): GetHomeworksForTeacherQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworksForTeacherPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworksForTeacherPost$Response(params?: ApiHomeworkGetHomeworksForTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworksForTeacherQueryResult>> {
    return apiHomeworkGetHomeworksForTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworksForTeacherPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworksForTeacherPost(params?: ApiHomeworkGetHomeworksForTeacherPost$Params, context?: HttpContext): Observable<GetHomeworksForTeacherQueryResult> {
    return this.apiHomeworkGetHomeworksForTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworksForTeacherQueryResult>): GetHomeworksForTeacherQueryResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkGetHomeworkStudentSolvesPost()` */
  static readonly ApiHomeworkGetHomeworkStudentSolvesPostPath = '/api/Homework/GetHomeworkStudentSolves';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworkStudentSolvesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkStudentSolvesPost$Plain$Response(params?: ApiHomeworkGetHomeworkStudentSolvesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworkStudentSolvesQueryResult>> {
    return apiHomeworkGetHomeworkStudentSolvesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworkStudentSolvesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkStudentSolvesPost$Plain(params?: ApiHomeworkGetHomeworkStudentSolvesPost$Plain$Params, context?: HttpContext): Observable<GetHomeworkStudentSolvesQueryResult> {
    return this.apiHomeworkGetHomeworkStudentSolvesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworkStudentSolvesQueryResult>): GetHomeworkStudentSolvesQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworkStudentSolvesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkStudentSolvesPost$Response(params?: ApiHomeworkGetHomeworkStudentSolvesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworkStudentSolvesQueryResult>> {
    return apiHomeworkGetHomeworkStudentSolvesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworkStudentSolvesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkStudentSolvesPost(params?: ApiHomeworkGetHomeworkStudentSolvesPost$Params, context?: HttpContext): Observable<GetHomeworkStudentSolvesQueryResult> {
    return this.apiHomeworkGetHomeworkStudentSolvesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworkStudentSolvesQueryResult>): GetHomeworkStudentSolvesQueryResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkGetHomeworkStudentsPost()` */
  static readonly ApiHomeworkGetHomeworkStudentsPostPath = '/api/Homework/GetHomeworkStudents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworkStudentsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkStudentsPost$Plain$Response(params?: ApiHomeworkGetHomeworkStudentsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworkStudentsQueryResult>> {
    return apiHomeworkGetHomeworkStudentsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworkStudentsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkStudentsPost$Plain(params?: ApiHomeworkGetHomeworkStudentsPost$Plain$Params, context?: HttpContext): Observable<GetHomeworkStudentsQueryResult> {
    return this.apiHomeworkGetHomeworkStudentsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworkStudentsQueryResult>): GetHomeworkStudentsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworkStudentsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkStudentsPost$Response(params?: ApiHomeworkGetHomeworkStudentsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworkStudentsQueryResult>> {
    return apiHomeworkGetHomeworkStudentsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworkStudentsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkStudentsPost(params?: ApiHomeworkGetHomeworkStudentsPost$Params, context?: HttpContext): Observable<GetHomeworkStudentsQueryResult> {
    return this.apiHomeworkGetHomeworkStudentsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworkStudentsQueryResult>): GetHomeworkStudentsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkGetHwStudentSolutionPost()` */
  static readonly ApiHomeworkGetHwStudentSolutionPostPath = '/api/Homework/GetHWStudentSolution';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHwStudentSolutionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHwStudentSolutionPost$Plain$Response(params?: ApiHomeworkGetHwStudentSolutionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHwStudentSolutionQueryResult>> {
    return apiHomeworkGetHwStudentSolutionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHwStudentSolutionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHwStudentSolutionPost$Plain(params?: ApiHomeworkGetHwStudentSolutionPost$Plain$Params, context?: HttpContext): Observable<GetHwStudentSolutionQueryResult> {
    return this.apiHomeworkGetHwStudentSolutionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHwStudentSolutionQueryResult>): GetHwStudentSolutionQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHwStudentSolutionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHwStudentSolutionPost$Response(params?: ApiHomeworkGetHwStudentSolutionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHwStudentSolutionQueryResult>> {
    return apiHomeworkGetHwStudentSolutionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHwStudentSolutionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHwStudentSolutionPost(params?: ApiHomeworkGetHwStudentSolutionPost$Params, context?: HttpContext): Observable<GetHwStudentSolutionQueryResult> {
    return this.apiHomeworkGetHwStudentSolutionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHwStudentSolutionQueryResult>): GetHwStudentSolutionQueryResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkGetHomeworkForStudentPost()` */
  static readonly ApiHomeworkGetHomeworkForStudentPostPath = '/api/Homework/GetHomeworkForStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworkForStudentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkForStudentPost$Plain$Response(params?: ApiHomeworkGetHomeworkForStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworkForStudentQueryResult>> {
    return apiHomeworkGetHomeworkForStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworkForStudentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkForStudentPost$Plain(params?: ApiHomeworkGetHomeworkForStudentPost$Plain$Params, context?: HttpContext): Observable<GetHomeworkForStudentQueryResult> {
    return this.apiHomeworkGetHomeworkForStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworkForStudentQueryResult>): GetHomeworkForStudentQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworkForStudentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkForStudentPost$Response(params?: ApiHomeworkGetHomeworkForStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworkForStudentQueryResult>> {
    return apiHomeworkGetHomeworkForStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworkForStudentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworkForStudentPost(params?: ApiHomeworkGetHomeworkForStudentPost$Params, context?: HttpContext): Observable<GetHomeworkForStudentQueryResult> {
    return this.apiHomeworkGetHomeworkForStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworkForStudentQueryResult>): GetHomeworkForStudentQueryResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkGetHomeworksForStudentPost()` */
  static readonly ApiHomeworkGetHomeworksForStudentPostPath = '/api/Homework/GetHomeworksForStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworksForStudentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworksForStudentPost$Plain$Response(params?: ApiHomeworkGetHomeworksForStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworksForStudentQueryResult>> {
    return apiHomeworkGetHomeworksForStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworksForStudentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworksForStudentPost$Plain(params?: ApiHomeworkGetHomeworksForStudentPost$Plain$Params, context?: HttpContext): Observable<GetHomeworksForStudentQueryResult> {
    return this.apiHomeworkGetHomeworksForStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworksForStudentQueryResult>): GetHomeworksForStudentQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkGetHomeworksForStudentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworksForStudentPost$Response(params?: ApiHomeworkGetHomeworksForStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworksForStudentQueryResult>> {
    return apiHomeworkGetHomeworksForStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkGetHomeworksForStudentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiHomeworkGetHomeworksForStudentPost(params?: ApiHomeworkGetHomeworksForStudentPost$Params, context?: HttpContext): Observable<GetHomeworksForStudentQueryResult> {
    return this.apiHomeworkGetHomeworksForStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetHomeworksForStudentQueryResult>): GetHomeworksForStudentQueryResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkStudentStartSolvingPost()` */
  static readonly ApiHomeworkStudentStartSolvingPostPath = '/api/Homework/StudentStartSolving';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkStudentStartSolvingPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkStudentStartSolvingPost$Plain$Response(params?: ApiHomeworkStudentStartSolvingPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentStartSolvingCommandResult>> {
    return apiHomeworkStudentStartSolvingPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkStudentStartSolvingPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkStudentStartSolvingPost$Plain(params?: ApiHomeworkStudentStartSolvingPost$Plain$Params, context?: HttpContext): Observable<StudentStartSolvingCommandResult> {
    return this.apiHomeworkStudentStartSolvingPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentStartSolvingCommandResult>): StudentStartSolvingCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkStudentStartSolvingPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkStudentStartSolvingPost$Response(params?: ApiHomeworkStudentStartSolvingPost$Params, context?: HttpContext): Observable<StrictHttpResponse<StudentStartSolvingCommandResult>> {
    return apiHomeworkStudentStartSolvingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkStudentStartSolvingPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkStudentStartSolvingPost(params?: ApiHomeworkStudentStartSolvingPost$Params, context?: HttpContext): Observable<StudentStartSolvingCommandResult> {
    return this.apiHomeworkStudentStartSolvingPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StudentStartSolvingCommandResult>): StudentStartSolvingCommandResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkSubmitHomeworkStudentPost()` */
  static readonly ApiHomeworkSubmitHomeworkStudentPostPath = '/api/Homework/SubmitHomeworkStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkSubmitHomeworkStudentPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkSubmitHomeworkStudentPost$Plain$Response(params?: ApiHomeworkSubmitHomeworkStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SubmitHomeworkStudentCommandResult>> {
    return apiHomeworkSubmitHomeworkStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkSubmitHomeworkStudentPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkSubmitHomeworkStudentPost$Plain(params?: ApiHomeworkSubmitHomeworkStudentPost$Plain$Params, context?: HttpContext): Observable<SubmitHomeworkStudentCommandResult> {
    return this.apiHomeworkSubmitHomeworkStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubmitHomeworkStudentCommandResult>): SubmitHomeworkStudentCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkSubmitHomeworkStudentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkSubmitHomeworkStudentPost$Response(params?: ApiHomeworkSubmitHomeworkStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<SubmitHomeworkStudentCommandResult>> {
    return apiHomeworkSubmitHomeworkStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkSubmitHomeworkStudentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkSubmitHomeworkStudentPost(params?: ApiHomeworkSubmitHomeworkStudentPost$Params, context?: HttpContext): Observable<SubmitHomeworkStudentCommandResult> {
    return this.apiHomeworkSubmitHomeworkStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubmitHomeworkStudentCommandResult>): SubmitHomeworkStudentCommandResult => r.body)
    );
  }

  /** Path part for operation `apiHomeworkSaveHomeworkAnswerPost()` */
  static readonly ApiHomeworkSaveHomeworkAnswerPostPath = '/api/Homework/SaveHomeworkAnswer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkSaveHomeworkAnswerPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkSaveHomeworkAnswerPost$Plain$Response(params?: ApiHomeworkSaveHomeworkAnswerPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SaveHomeworkAnswerCommandResult>> {
    return apiHomeworkSaveHomeworkAnswerPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkSaveHomeworkAnswerPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkSaveHomeworkAnswerPost$Plain(params?: ApiHomeworkSaveHomeworkAnswerPost$Plain$Params, context?: HttpContext): Observable<SaveHomeworkAnswerCommandResult> {
    return this.apiHomeworkSaveHomeworkAnswerPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SaveHomeworkAnswerCommandResult>): SaveHomeworkAnswerCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeworkSaveHomeworkAnswerPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkSaveHomeworkAnswerPost$Response(params?: ApiHomeworkSaveHomeworkAnswerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<SaveHomeworkAnswerCommandResult>> {
    return apiHomeworkSaveHomeworkAnswerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHomeworkSaveHomeworkAnswerPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHomeworkSaveHomeworkAnswerPost(params?: ApiHomeworkSaveHomeworkAnswerPost$Params, context?: HttpContext): Observable<SaveHomeworkAnswerCommandResult> {
    return this.apiHomeworkSaveHomeworkAnswerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<SaveHomeworkAnswerCommandResult>): SaveHomeworkAnswerCommandResult => r.body)
    );
  }

}
