<div id="layout-wrapper">

  <app-sidebar class="sticky-lg-top" [isCondensed]="isCondensed"></app-sidebar>

  <div>
    <div class="main-content h-100" style="background: url(../../../assets/images/edu-pattern.webp) repeat ;background-size: 350px;background-attachment: fixed;">
      <ng-content select="[hehehe]"></ng-content>

      <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" class="sticky-lg-top"
        (mobileMenuButtonClicked)="onToggleMobileMenu()">
      </app-topbar>
      <div class="px-2 px-md-3 mw-1920px w-100 mx-auto">
        <!-- content -->
        <router-outlet>
        </router-outlet>
      </div>
      <mg-left-sidebar class=""></mg-left-sidebar>

      <!-- footer -->
      <!-- <app-footer></app-footer> -->
    </div>
  </div>



</div>

<ng-container *appRole="[roles.Admin, roles.Student]">
  <a *ngIf="platformUpdates?.length" (click)="openUpdatesTemp()" class="support_fixed pointer">
    <i class="isax-information bx-tada"></i>
  </a>
</ng-container>
<ng-container *appRole="[roles.Teacher, roles.Student]">
  <a *ngIf="platformUpdates?.length" (click)="openFeedbackTemp()" class="support_fixed feedback bg-danger pointer">
    <i class="isax-message-edit"></i>
  </a>
</ng-container>


<ng-template #feedbackTemp let-modal>

  <div class="modal-body p-4">
    <form class="w-100">
      <label for="">
        اترك تعليقك على استخدامك للمنصة وإذا كان لديك أي مقترحات لإضافتها في منصة المفيد 😊
      </label>
      <textarea nz-input [(ngModel)]="feedbackMessage" placeholder="اكتب تعليقًا .." name="feedbackMessage" id="" cols="30" rows="8" id=""></textarea>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-light" (click)="modal.close('Save click')">
      إغــلاق
    </button>
    <button type="button" [disabled]="sendingFeedback" class="btn btn-sm btn-primary" [disabled]="!feedbackMessage" (click)="sendFeedback()">
      <span class="bx bx-loader bx-spin" *ngIf="sendingFeedback"></span>
      إرســال
    </button>
  </div>
</ng-template>
<ng-template #updatesTemp let-modal>

  <div class="modal-body p-4">
    <div class="d-flex gap-3 border-bottom align-items-start w-100 py-2" *ngFor="let change of platformUpdates">
      <span class="">

        <button class="btn btn-sm btn-icon btn-light text-white p-2 rounded-circle">
          🚀
        </button>
      </span>
      <div>
        <p class="text-muted d-flex gap-1 align-items-center small mb-0">
          <span class="isax-calendar-1"></span>
          <span>
            {{change.changeAt | date}}
          </span>
        </p>
        <span>
          <small>
            {{change.title}}
          </small>
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="modal.close('Save click')">
      إغــلاق
    </button>
  </div>
</ng-template>