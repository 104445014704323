/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetHomeworkStudentsQuery } from '../../models/get-homework-students-query';
import { GetHomeworkStudentsQueryResult } from '../../models/get-homework-students-query-result';

export interface ApiHomeworkGetHomeworkStudentsPost$Plain$Params {
      body?: GetHomeworkStudentsQuery
}

export function apiHomeworkGetHomeworkStudentsPost$Plain(http: HttpClient, rootUrl: string, params?: ApiHomeworkGetHomeworkStudentsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetHomeworkStudentsQueryResult>> {
  const rb = new RequestBuilder(rootUrl, apiHomeworkGetHomeworkStudentsPost$Plain.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetHomeworkStudentsQueryResult>;
    })
  );
}

apiHomeworkGetHomeworkStudentsPost$Plain.PATH = '/api/Homework/GetHomeworkStudents';
