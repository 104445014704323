/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DeleteTalentedStudentCommand } from '../../models/delete-talented-student-command';
import { DeleteTalentedStudentCommandResult } from '../../models/delete-talented-student-command-result';

export interface ApiTalentedStudentsDeleteTalentedStudentPost$Plain$Params {
      body?: DeleteTalentedStudentCommand
}

export function apiTalentedStudentsDeleteTalentedStudentPost$Plain(http: HttpClient, rootUrl: string, params?: ApiTalentedStudentsDeleteTalentedStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTalentedStudentCommandResult>> {
  const rb = new RequestBuilder(rootUrl, apiTalentedStudentsDeleteTalentedStudentPost$Plain.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DeleteTalentedStudentCommandResult>;
    })
  );
}

apiTalentedStudentsDeleteTalentedStudentPost$Plain.PATH = '/api/TalentedStudents/DeleteTalentedStudent';
