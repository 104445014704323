/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FilteredQuery } from '../../models/filtered-query';
import { GetStudentAnsweredExamsQueryResult } from '../../models/get-student-answered-exams-query-result';
import { SortedQuery } from '../../models/sorted-query';

export interface ApiTExamGetStudentAnsweredExamsPost$Params {
      body?: {
'TeacherId'?: string;
'CourseTeachers'?: Array<string>;
'title'?: string;
'StudentId'?: string;
'PageNumber'?: number;
'PageSize'?: number;
'Filters'?: Array<FilteredQuery>;
'Sorts'?: Array<SortedQuery>;
'SearchTerm'?: string;
}
}

export function apiTExamGetStudentAnsweredExamsPost(http: HttpClient, rootUrl: string, params?: ApiTExamGetStudentAnsweredExamsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentAnsweredExamsQueryResult>> {
  const rb = new RequestBuilder(rootUrl, apiTExamGetStudentAnsweredExamsPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetStudentAnsweredExamsQueryResult>;
    })
  );
}

apiTExamGetStudentAnsweredExamsPost.PATH = '/api/TExam/GetStudentAnsweredExams';
