 <!-- ?? langs -->
 <div  class="dropdown d-inline-block" ngbDropdown>
    <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
      <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
        class="ms-1">{{countryName | translate}}</span>
      <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
      <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
    </button>
    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
      <!-- item-->
      <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
        (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
        <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
          class="align-middle">{{item.text | translate}}</span>
      </a>
      <!-- item-->
    </div>
  </div>