/* tslint:disable */
/* eslint-disable */
export enum FilterType {
  Equals = 'Equals',
  NotEquals = 'NotEquals',
  Range = 'Range',
  Contains = 'Contains',
  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqual',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual'
}
