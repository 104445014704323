/* tslint:disable */
/* eslint-disable */
export enum ErrorCode {
  Error = 'Error',
  FieldRequired = 'FieldRequired',
  MaxFieldLength = 'MaxFieldLength',
  MinFieldLength = 'MinFieldLength',
  UserNotFound = 'UserNotFound',
  InvalidLogin = 'InvalidLogin',
  DuplicateUser = 'DuplicateUser',
  DuplicateEmail = 'DuplicateEmail',
  DuplicateName = 'DuplicateName',
  DuplicatePhoneNumber = 'DuplicatePhoneNumber',
  NotFound = 'NotFound',
  UserAlreadyLoggedIn = 'UserAlreadyLoggedIn',
  InvalidEmailAddress = 'InvalidEmailAddress',
  InvalidPhoneNumber = 'InvalidPhoneNumber',
  InvalidUsername = 'InvalidUsername',
  Expired = 'Expired',
  InvalidPasswordRequirements = 'InvalidPasswordRequirements',
  InvalidStartDate = 'InvalidStartDate',
  InvalidEndDate = 'InvalidEndDate',
  InvalidDate = 'InvalidDate',
  CantLockAdmin = 'CantLockAdmin',
  CantLockYourself = 'CantLockYourself',
  NotEnoughAnswers = 'NotEnoughAnswers',
  CantDelete = 'CantDelete',
  DuplicateCourse = 'DuplicateCourse',
  AccessDenied = 'AccessDenied',
  CantCheckAnswer = 'CantCheckAnswer',
  WrongAnswer = 'WrongAnswer',
  NotStudent = 'NotStudent',
  Freezed = 'Freezed',
  DidnotSubscribe = 'DidnotSubscribe',
  CantAddTask = 'cantAddTask',
  CompleteLatestTask = 'CompleteLatestTask',
  InvalidToken = 'InvalidToken',
  StudentAlreadyJoin = 'StudentAlreadyJoin',
  CourseFreezed = 'CourseFreezed',
  StudentFreezed = 'StudentFreezed',
  AlreadyEmailConfirmed = 'AlreadyEmailConfirmed',
  CantSendEmail = 'CantSendEmail',
  CantChangeEmail = 'CantChangeEmail',
  NotActiveForLongTime = 'NotActiveForLongTime',
  AccountNotHaveValidity = 'AccountNotHaveValidity',
  SubscriptionAlreadySent = 'SubscriptionAlreadySent',
  CantSort = 'CantSort',
  LessonIsNotActive = 'LessonIsNotActive',
  MustChooseTeacher = 'MustChooseTeacher',
  MustChooseAtLeastOneCourse = 'MustChooseAtLeastOneCourse',
  CoursesNotBelongToTeacher = 'CoursesNotBelongToTeacher',
  ExamNotFound = 'ExamNotFound',
  PlzChooseAtLeastOneExam = 'PLZChooseAtLeastOneExam',
  CoursesNotBelongToYou = 'CoursesNotBelongToYou',
  YouCantUpdateStartedExam = 'YouCantUpdateStartedExam',
  ExamNotBelongToYou = 'ExamNotBelongToYou',
  ExamTimeOut = 'ExamTimeOut',
  ExamTimeOutButSaved = 'ExamTimeOutButSaved',
  ExamExpired = 'ExamExpired',
  ExamDontBelongToTeacher = 'ExamDontBelongToTeacher',
  PlzChooseStudent = 'PLZChooseStudent',
  QuestionNumberIsDuplicated = 'QuestionNumberIsDuplicated',
  NotSubQuestions = 'NotSubQuestions',
  LessonNotBelongToYou = 'LessonNotBelongToYou',
  HomeworkNotBelongToYou = 'HomeworkNotBelongToYou',
  CouresIsNotBelongToYou = 'CouresIsNotBelongToYou',
  CouresNotFound = 'CouresNotFound',
  YouCantSolveHomeworkMoreTimes = 'YouCantSolveHomeworkMoreTimes',
  CantUpdateQuestionPoints = 'CantUpdateQuestionPoints',
  HomeWorkExpired = 'HomeWorkExpired',
  HomeworkNotStartedYet = 'HomeworkNotStartedYet',
  HomeWorkTimedOut = 'HomeWorkTimedOut'
}
